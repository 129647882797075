import * as React from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import TableGrid from './TableGrid/TableGrid';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import Optionconfig from '../../config/Optionconfig';
import ActionUrl from '../../config/ActionUrl';

export default function Dashboard({ signIn }) {
  const navigate = useNavigate();

  React.useEffect(() => {
    let authData = localStorage.getItem('AuthData');
    if (!authData) {
      signIn(false);
    }
  }, []);

  const QuotationCreate = () => {
    Optionconfig({
        method: 'GET',
        url: ActionUrl.quotation,
        tokens: localStorage.getItem("AuthData"),
        sendback: (response) => {
          navigate('/quotation/create', {state: response});
        }
    });
  }

  return (
    <div style={{ width: '100%' }}>

      <Grid container my={2} width={"80%"} style={{'margin': "auto"}}>
          <Grid item xs={12} sm={12}>
          <Box align="center" style={{'margin': "10px"}}>
        <h2>Quotation Form</h2>
      </Box>
      <Box align="right" style={{'margin': "10px"}}>
        <Button variant="contained" onClick={QuotationCreate}>Create Quotation</Button>
      </Box>
          </Grid>
      </Grid>


      <Grid container my={2} width={"80%"} style={{'margin': "auto"}}>
          <Grid item xs={12} sm={12}>
            <TableGrid />
          </Grid>
      </Grid>
    </div>
  );
}
