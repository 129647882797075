import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Extraitem from "./ExtraItem";
import Switch from '@mui/material/Switch';
import { json } from "react-router-dom";

export default function CounterTop({
    countertopFinish,
    editcountertop,
    handleSave
}) {
    let [dimension, setDimension] = React.useState({});

    useEffect(() => {
        DataProcessing(dimension);
    }, [dimension]);
    useEffect(() => {
        if (editcountertop) {
            const finddata = countertopFinish.findIndex(item => { return editcountertop.dimensionB.id === item.id });
            setDimension(prevState => ({ ...prevState, ['dimensionA']: editcountertop.dimensionA }));
            setDimension(prevState => ({ ...prevState, ['dimensionC']: editcountertop.dimensionC }));
            setDimension(prevState => ({ ...prevState, ['dimensionD']: editcountertop.dimensionD }));
            setDimension(prevState => ({ ...prevState, ['dimensionB']: countertopFinish[finddata] }));
            setDimension(prevState => ({ ...prevState, ["checkedA"] : booleanIdentifier(editcountertop.checkedA) }));
            setDimension(prevState => ({ ...prevState, ['list']: editcountertop.list }));
            setDimension(prevState => ({ ...prevState, ['extraListCost']: editcountertop.extraListCost }));
        }
    }, []);

    const booleanIdentifier = (data) => {
        if(data === '1' || data === 'true' || data === true){
            return true;
        }
        if(data === '0' || data === 'false' || data === false){
            return false;
        }
    }

    const DataProcessing = (data) => {
        TotalCounterTopCost(data.dimensionA || 0, data.dimensionB || countertopFinish[0],data.dimensionC || 0,data.dimensionD || 0, data.extraListCost || 0);
    }

    const TotalCounterTopCost = (dimensionA, dimensionB, dimensionC, dimensionD, extraListCost) => {
        if (dimensionA > 0 || dimensionB > 0 && dimensionC > 0) {
            setDimension(prevState => ({ ...prevState, ['total_countertop_cost']: (parseFloat((parseInt(dimensionA) * 80) / 900) * parseInt(dimensionB.price)).toFixed(2) }));
            setDimension(prevState => ({ ...prevState, ['total_walltile_cost']: (parseFloat(parseFloat((parseInt(dimensionC) * parseInt(dimensionD)) / 900)) * 200).toFixed(2) }));
            setDimension(prevState => ({ ...prevState, ['total_cost']: ((parseFloat(dimension.total_countertop_cost) + parseFloat(dimension.total_walltile_cost) + parseFloat(extraListCost))).toFixed(2) }));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!dimension.dimensionB){
            dimension.dimensionB = countertopFinish[0];
        }
        handleSave({
            name: "countertop",
            value: dimension
        });
    }

    const handleChange = (e) => {
        if (e.target.name === "checkedA") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        } else {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }

    const addItemEvent = (data) => {
        setDimension(prevState => ({ ...prevState, ['list']: JSON.stringify(data.list) }));
        setDimension(prevState => ({ ...prevState, ['extraListCost']: data.extra_list_cost }));
    }

    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            mt={3}
        >
            <Grid container spacing={2}>
                <Grid container mt={2} xs={12} sm={8}>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the total length of the Counter Top (In cm)"
                                    name="dimensionA"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionA || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    select
                                    label="Choose the material of Counter-top for the kitchen"
                                    fullWidth
                                    name='dimensionB'
                                    value={dimension.dimensionB || countertopFinish[0]}
                                    align="left"
                                    onChange={handleChange}
                                >
                                    {countertopFinish.map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"93%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the length of the Wall tile (In cm)"
                                    name="dimensionC"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionC || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the height of the Wall tile (In cm)"
                                    name="dimensionD"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionD || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total Counter-top Cost:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.total_countertop_cost || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total Wall Tile Cost:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.total_walltile_cost || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total Cost:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.total_cost || 0.00}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid container xs={12} sm={12}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                        Any extra Line Item needed?
                        <Switch
                            name='checkedA'
                            checked={dimension.checkedA || false}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                    {dimension.checkedA ?
                        <Extraitem addItemEvent={addItemEvent} editlist={dimension.list ? JSON.parse(dimension.list) : []}/>
                    : ""}
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </Box>
        </Box>
    )
}