import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ActionUrl from '../../../../config/ActionUrl';
import Optionconfig from '../../../../config/Optionconfig';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

export default function PopUpModal({ open, close, selectCustomer }) {

    const handleClose = () => close(false);


    const handleDelete = () => {
        Optionconfig({
            method: 'POST',
            url: ActionUrl.quotationDelete,
            body: {
                customer: selectCustomer
            },
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                if(response.status === 'success') {
                    close(false);
                }
            }
        });
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ "text-align" : "center"}}>
                        Do you delete this Customer..?
                    </Typography>
                    <Box id="modal-modal-description" sx={{ mt: 3 }} style={{ display: 'flex', justifyContent: "space-around"}}>
                        <Button variant="outlined" onClick={handleClose}>Cancel</Button>
                        <Button variant="outlined" onClick={handleDelete} color="error">Save</Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
