const Optionconfig = (props) => {
    let tokens = JSON.parse(props.tokens);
    if(tokens){
        let option = {
            method: props.method,
            headers: new Headers({
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': tokens.auth_token_type + ' ' + tokens.auth_token,
            }),
            body: JSON.stringify(props.body)
        };

        fetch(
            props.url, option)
            .then((res) => res.json())
            .then((response) => {
                props.sendback(response);
            });
    }
}

export default Optionconfig;