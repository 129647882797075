import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AccessoryTable from "./component/AccessoryTable";

export default function Otheritems({
    editotheritems,
    handleSave
}) {
    let [totallist, setTotallist] = React.useState([]);
    const [dimension, setDimension] = React.useState({});
    useEffect(() => {
        if(editotheritems) {
            setTotallist(editotheritems.list);
        }
    },[editotheritems]);

    const handleChange = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const AddExtralist = () => {
        const totallistdata = [...totallist, {
            id: parseInt(totallist.length + 1),
            name: dimension.dimensionA,
            quantity: parseInt(dimension.quantity),
            totalcost: parseInt(dimension.price) * parseInt(dimension.quantity)
        }];
        const sortedData = totallistdata.sort((a, b) => a.name.localeCompare(b.name));
        setTotallist(sortedData);
        setDimension({});
    }

    const removeList = (data) => {
        setTotallist(totallist.filter((_, item) => item !== data.index));
    }

    const handleSubmit = () => {
        let appliancetotalcost = 0;
        if (totallist) {
            appliancetotalcost = totallist.reduce((sum, a) => {
                return sum + a.totalcost;
            }, 0);
        }

        handleSave({
            name: 'otheritems',
            value: {
                list: totallist,
                total_cost: appliancetotalcost,
            }
        });
    }
    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            align="center"
            mt={3}
        >

            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Particulars"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    name="dimensionA"
                                    value={dimension.dimensionA || ""}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Quantity"
                                    name="quantity"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.quantity || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2} sm={2}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Price"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.price || 0}
                                    name="price"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Fab color="primary" aria-label="add" onClick={AddExtralist}>
                                <AddIcon />
                            </Fab>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <AccessoryTable
                        columnlist={['Item', 'Quantity', 'Total', 'Action']}
                        datalist={totallist}
                        ondelete={removeList}
                    />
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </Box>
        </Box>
    )
}