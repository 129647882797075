import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import ActionUrl from '../../../config/ActionUrl';
import Optionconfig from '../../../config/Optionconfig';
import PopUpModal from './component/PopUpModel';
import { useNavigate } from 'react-router-dom';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

export default function TableGrid() {
    const [details, setDetails] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectDelete, setSelectDelete] = useState(null);
    const navigate = useNavigate();
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 10;

    const ListDetails = () => {
        Optionconfig({
            method: 'GET',
            url: ActionUrl.quotationCollection,
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                setDetails(response.quotationcollection);
            }
        });
    }

    useEffect(() => {
        // Fetch items from another resources.
        Optionconfig({
            method: 'GET',
            url: ActionUrl.quotationCollection,
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                setDetails(response.quotationcollection);
                const endOffset = itemOffset + itemsPerPage;
                // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
                setCurrentItems(response.quotationcollection.slice(itemOffset, endOffset));
                setPageCount(Math.ceil(response.quotationcollection.length / itemsPerPage));
            }
        });

        if (!open) {
            ListDetails();
        }

    }, [itemOffset, itemsPerPage, open]);


    const ViewEvent = (item) => {
        const link = document.createElement("a");
        link.download = 'QuotationPdf.pdf';
        link.href = item.invoic_pdf_url;
        link.setAttribute("target", "_blank");
        link.click();
        link.remove();
    }

    const DeleteEvent = (item) => {
        setOpen(true);
        setSelectDelete(item);
    }

    const EditEvent = (item) => {
        Optionconfig({
            method: 'POST',
            url: ActionUrl.quotationEdit,
            body: {
                customer: item
            },
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                if (response.status === 'success') {
                    navigate('/quotation/create', { state: response });
                    // editCustomerEvent(response);
                } else {
                    // setError(response);
                }
            }
        });
    }

    const handlePageClick = (event, value) => {
        const newOffset = ((value - 1) * itemsPerPage) % details.length;
        // console.log(`User requested page number ${value - 1}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };

    const handleFilteration = (event) => {
        // console.log(event.target.value);
        // Fetch items from another resources.
        Optionconfig({
            method: 'GET',
            url: ActionUrl.quotationCollection+'?' + new URLSearchParams({
                customer_name: event.target.value
            }),
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                setDetails(response.quotationcollection);
                const endOffset = itemOffset + itemsPerPage;
                // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
                setCurrentItems(response.quotationcollection.slice(itemOffset, endOffset));
                setPageCount(Math.ceil(response.quotationcollection.length / itemsPerPage));
            }
        });
    }

    return (
        <>
            <Box
                component="form"
                sx={{
                    '& > :not(style)': { m: 1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
            >
                <TextField id="outlined-basic" label="Search Customer Name..." variant="outlined" focused onChange={handleFilteration}/>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Quotation No</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{currentItems.length > 0 ?
                        currentItems.map((row, index) =>
                            <TableRow key={index}>
                                {row.q_number ? <TableCell>{row.q_number}</TableCell> : ""}
                                {row.customer_name ? <TableCell>{row.customer_name}</TableCell> : row.name ? <TableCell>{row.name}</TableCell> : ""}
                                {row.date ? <TableCell>{new Date(row.date).toLocaleDateString("en-US")}</TableCell> : ""}
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        startIcon={<EditIcon />}
                                        onClick={() => EditEvent(row)}
                                    >
                                        Edit
                                    </Button>
                                    <Button variant="outlined" onClick={() => ViewEvent(row)}>
                                        View
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        startIcon={<DeleteIcon />}
                                        onClick={() => DeleteEvent(row)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>)
                        : ""}</TableBody>
                </Table>
                <PopUpModal open={open} close={setOpen} selectCustomer={selectDelete} />
            </TableContainer>
            <Stack spacing={2} sx={{ my: 2 }}>
                <Pagination count={pageCount} variant="outlined" color="primary" onChange={handlePageClick} />
            </Stack>
        </>
    );
}
