import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AccessoryTable from "./component/AccessoryTable";
import Switch from '@mui/material/Switch';

export default function OtherInteriors({
    OtherInteriors,
    editotherinteriors,
    handleSave
}) {
    const [interiors, setInteriors] = React.useState(OtherInteriors[0]);
    const [quantity, setQuantity] = React.useState(1);
    let [totallist, setTotallist] = React.useState([]);
    const [dimension, setDimension] = React.useState({});

    const [error, setError] = React.useState({
        dimensionA: null,
    });

    useEffect(() => {
        if (editotherinteriors) {
            setTotallist(editotherinteriors.list);
        }
    }, [editotherinteriors]);

    const Addlist = () => {
        if (totallist) {
            const finddata = totallist.findIndex(item => { return interiors.id === item.id });
            // update
            if (totallist[finddata]) {
                totallist[finddata].quantity = parseInt(quantity);
                totallist[finddata].totalcost = parseInt(interiors.price) * parseInt(quantity);
                setTotallist(totallist => [...totallist.slice(0, finddata), totallist[finddata], ...totallist.slice(finddata + 1)]);
            } else {
                // insert
                setTotallist(totallist => [...totallist, {
                    ...interiors,
                    quantity: parseInt(quantity),
                    totalcost: parseInt(interiors.price) * parseInt(quantity)
                }]);
            }
        } else {
            setTotallist(totallist => [...[], {
                ...interiors,
                quantity: parseInt(quantity),
                totalcost: parseInt(interiors.price) * parseInt(quantity)
            }]);
        }
    }

    const handleChange = (e) => {
        if (e.target.name === "checkedA") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        } else {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }

    const AddExtralist = () => {
        if (!dimension.dimensionA) {
            setError({
                dimensionA: true
            });
        } else {
            setTotallist(totallist => [...totallist, {
                id: parseInt(OtherInteriors[OtherInteriors.length - 1].id) + parseInt(Math.floor((Math.random() * 10000) + 1)),
                name: dimension.dimensionA,
                quantity: parseInt(dimension.quantity),
                totalcost: parseInt(dimension.price) * parseInt(dimension.quantity)
            }]);

            setError({
                dimensionA: false
            });

            setDimension({});
        }
    }

    const removeList = (data) => {
        setTotallist(totallist.filter((_, item) => item !== data.index));
    }

    const handleSubmit = () => {
        let appliancetotalcost = 0;
        if (totallist) {
            appliancetotalcost = totallist.reduce((sum, a) => {
                return sum + a.totalcost;
            }, 0);
        }

        handleSave({
            name: 'otherinteriors',
            value: {
                list: totallist,
                total_cost: appliancetotalcost,
            }
        });
    }
    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            align="center"
            mt={3}
        >

            <Grid container>
                <Grid item xs={12} sm={8}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align={'left'}>
                        <TextField
                            select
                            fullWidth
                            label="Choose Others Interiors"
                            value={interiors}
                            onChange={e => setInteriors(e.target.value)}
                            helperText="Please Select your Interiors"
                        >
                            {OtherInteriors.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                        <TextField
                            required
                            fullWidth
                            type={'number'}
                            label="Quantity"
                            autoFocus
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Fab color="primary" aria-label="add" onClick={Addlist}>
                        <AddIcon />
                    </Fab>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                        Any extra Line Item needed?
                        <Switch
                            name='checkedA'
                            checked={dimension.checkedA || false}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                    {dimension.checkedA ?
                        <Grid container xs={12} sm={12}>
                            <Grid item xs={6} sm={6}>
                                <Box component={'div'} mx={'auto'} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Particulars"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        name="dimensionA"
                                        value={dimension.dimensionA || ""}
                                        onChange={handleChange}
                                        error={error.dimensionA ? true : false}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        type={'number'}
                                        label="Quantity"
                                        name="quantity"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={dimension.quantity || 0}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        type={'number'}
                                        label="Price"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={dimension.price || 0}
                                        name="price"
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Fab color="primary" aria-label="add" onClick={AddExtralist}>
                                    <AddIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                        : ""}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <AccessoryTable
                        columnlist={['Item', 'Quantity', 'Total', 'Action']}
                        datalist={totallist}
                        ondelete={removeList}
                    />
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </Box>
        </Box>
    )
}