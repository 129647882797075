import React from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

export default function WardrobeSelect({
    steps,
    activeStep,
    handleBack,
    handleNext,
    selectWardrobe,
    setSelectWardrobe,
}) {
    const [selectWard, setSelectWard] = React.useState(selectWardrobe.no_of_wardrobes);
    React.useEffect(() => {
        if(selectWardrobe || selectWardrobe.no_of_wardrobes){
            setSelectWard(selectWardrobe.no_of_wardrobes);
        }
    }, [selectWardrobe]);
    const currencies = [
        {
            value: '1',
            label: '1',
        },
        {
            value: '2',
            label: '2',
        },
        {
            value: '3',
            label: '3',
        },
        {
            value: '4',
            label: '4',
        },
        {
            value: '5',
            label: '5',
        },
    ];

    const handleChange = (e) => {
        setSelectWard(e.target.value);
    }

    const OnSave = () => {
        setSelectWardrobe({
            no_of_wardrobes: selectWard
        });
        handleNext();
    }


    return (
        <Box component={'div'} my={2}>
            <Grid container>
                <Grid item sm={11} alignItems={'center'} m={'auto'}>
                    <TextField
                        select
                        name="no_of_wardrobes"
                        label="Please choose how many Wardrobes needed"
                        fullWidth
                        value={selectWard}
                        onChange={handleChange}
                    >
                        {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>



            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button onClick={OnSave} variant="contained">
                    {activeStep === steps.length - 1 ? 'Finish' : 'Save and Next'}
                </Button>
            </Box>
        </Box>

    )
}

