import React from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import AccessoryTable from "./component/AccessoryTable";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

export default function Extraitem({
    addItemEvent,
    editlist
}) {
    let [dimension, setDimension] = React.useState({});
    let [totallist, setTotallist] = React.useState([]);

    const [error, setError] = React.useState({
        dimensionA: null,
    });

    React.useEffect(() => {
        if (editlist.length > 0) {
            setTotallist(editlist);
        }
    }, []);

    const handleChange = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
    const removeList = (data) => {
        let action = totallist.filter((_, item) => item !== data.index);
        setTotallist(action);
        let appliancetotalcost = 0;
        if (action) {
            appliancetotalcost = action.reduce((sum, a) => {
                return sum + a.totalcost;
            }, 0);
        }
        addItemEvent({
            list: action,
            extra_list_cost: parseInt(appliancetotalcost)
        });
    }

    const AddExtralist = () => {
        if (!dimension.dimensionA) {
            setError({
                dimensionA: true
            });
        } else {
            setTotallist(totallist => [...totallist, {
                id: parseInt(totallist.length + 1),
                name: dimension.dimensionA,
                quantity: parseInt(dimension.quantity),
                totalcost: parseInt(dimension.price) * parseInt(dimension.quantity)
            }]);

            if (totallist.length > 0) {
                let appliancetotalcost = 0;
                if (totallist) {
                    appliancetotalcost = totallist.reduce((sum, a) => {
                        return sum + a.totalcost;
                    }, 0);
                }
                addItemEvent({
                    list: totallist.concat({
                        id: parseInt(totallist.length + 1),
                        name: dimension.dimensionA,
                        quantity: parseInt(dimension.quantity),
                        totalcost: parseInt(dimension.price) * parseInt(dimension.quantity)
                    }),
                    extra_list_cost: parseInt(appliancetotalcost) + parseInt(parseInt(dimension.price) * parseInt(dimension.quantity))
                });
            } else {
                let data = {
                    id: parseInt(totallist.length + 1),
                    name: dimension.dimensionA,
                    quantity: parseInt(dimension.quantity),
                    totalcost: parseInt(dimension.price) * parseInt(dimension.quantity)
                };
                addItemEvent({
                    list: totallist.length < 2 ? [data] : data,
                    extra_list_cost: parseInt(dimension.price) * parseInt(dimension.quantity)
                });
            }

            setError({
                dimensionA: false
            });

            setDimension({});
        }
    }

    return (
        <Grid container xs={12} sm={12}>
            <Grid item xs={6} sm={6}>
                <Box component={'div'} mx={'auto'} width={"90%"}>
                    <TextField
                        required
                        fullWidth
                        label="Particulars"
                        autoFocus
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        name="dimensionA"
                        value={dimension.dimensionA || ""}
                        onChange={handleChange}
                        error={error.dimensionA ? true : false}
                    />
                </Box>
            </Grid>
            <Grid item xs={2} sm={2}>
                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                    <TextField
                        required
                        fullWidth
                        type={'number'}
                        label="Quantity"
                        name="quantity"
                        autoFocus
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={dimension.quantity || 0}
                        onChange={handleChange}
                    />
                </Box>
            </Grid>
            <Grid item xs={2} sm={2}>
                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                    <TextField
                        required
                        fullWidth
                        type={'number'}
                        label="Price"
                        autoFocus
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{ inputProps: { min: 0 } }}
                        value={dimension.price || 0}
                        name="price"
                        onChange={handleChange}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Fab color="primary" aria-label="add" onClick={AddExtralist}>
                    <AddIcon />
                </Fab>
            </Grid>
            <Grid item xs={12} sm={12}>
                <AccessoryTable
                    columnlist={['Item', 'Quantity', 'Total', 'Action']}
                    datalist={totallist}
                    ondelete={removeList}
                />
            </Grid>
        </Grid>
    )
}