import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function Shoerack({
    shoerackitems,
    cabinetryFinishes,
    handleSave
}) {
    let [dimension, setDimension] = React.useState({});
    useEffect(() => {
        DataProcessing(dimension);
    }, [dimension]);

    useEffect(() => {
        if (shoerackitems) {
            DataFetching(shoerackitems);
        }
    }, []);

    const DataFetching = (data) => {
        for ( var property in data ) {
            if(property === "dimensionA"){
                setDimension(prevState => ({ ...prevState, ["dimensionA"] : data.dimensionA }));
            } else if(property === "dimensionB"){
                setDimension(prevState => ({ ...prevState, ["dimensionB"] : data.dimensionB }));
            } else if(property === "dimensionC"){
                const finddata = cabinetryFinishes.findIndex(item => {return data.dimensionC.id === item.id});
                setDimension(prevState => ({ ...prevState, ["dimensionC"] : cabinetryFinishes[finddata] }));
            } else {

            }
        }
    }

    const DataProcessing = (data) => {
        BackPanel(data.dimensionA || 0, data.dimensionB || 0, data.dimensionC || cabinetryFinishes[0]);
    }

    const BackPanel = (dimensionA, dimensionB, dimensionC) => {
        setDimension(prevState => ({ ...prevState, ['SqftOfBase']: ((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['total_cost']: (parseFloat(((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2)) * parseInt(dimensionC['price'])).toFixed(2) }));
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave({
            name: "shoerack",
            value: dimension
        });
    }

    const handleChange = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            mt={3}
        >
            <Grid container spacing={2}>
                <Grid container mt={2} xs={12} sm={8}>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the Length of the Shoe rack (In cm)"
                                    name="dimensionA"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionA || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the Height of the Shoe rack (In cm)"
                                    name="dimensionB"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionB || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    select
                                    label="Select the Finish"
                                    fullWidth
                                    name='dimensionC'
                                    value={dimension.dimensionC || cabinetryFinishes[0]}
                                    align="left"
                                    onChange={handleChange}
                                >
                                    {cabinetryFinishes.map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Sqft of Base:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.SqftOfBase || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Rate of the Shoe Rack:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.total_cost || 0.00}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </Box>
        </Box>
    )
}