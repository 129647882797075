import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import KitchenSelect from './KitchenSelect';
// import CabinetryForm from './CabinetryForm';
// import AccessoryForm from './AccessoryForm';
import StraightCabinetryForm from './straight/CabinetryForm';
import StraightAccessoryForm from './straight/AccessoryForm';
import LshapeCabinetryForm from './lshape/CabinetryForm';
import LshapeAccessoryForm from './lshape/AccessoryForm';
import UshapeCabinetryForm from './ushape/CabinetryForm';
import UshapeAccessoryForm from './ushape/AccessoryForm';
import IslandCabinetryForm from './island/CabinetryForm';
import IslandAccessoryForm from './island/AccessoryForm';
import CalcluateForm from './CalculateForm';

export default function WorkAreaStepperTab({ workarea_interior, handleSave, kitchenlist, cabinetryFinishes, accessories, q_number, kitchenMaterial }) {
    const steps = ['Type of Kitchen', 'Cabinetry Calc', 'Accessories Calc', 'Total Kitchen Cost'];
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [customer, setCustomer] = React.useState(null);
    const [kitchan, setKitchan] = React.useState(kitchenlist[0]);
    const [cabinetry, setCabinetry] = React.useState(null);
    const [accessory, setAccessory] = React.useState([]);
    const [final,setFinal] = React.useState(null);

    React.useEffect(() => {
        if (workarea_interior) {
            // setCustomer(editCustomerDetails.Customer);
            setKitchan(workarea_interior.kitchan);
            setCabinetry(workarea_interior.cabinetry);
            setAccessory(workarea_interior.accessory);
        }
    }, [workarea_interior])

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep === steps.length - 1) {
            // handleClose(false);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const CabinetryFormDisplay = (kitchanId) => {
        if (kitchanId && kitchanId.id === 2) {
            return (
                <LshapeCabinetryForm
                    steps={steps}
                    cabinetryFinishes={cabinetryFinishes}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    Cabinetry={cabinetry}
                    Customer={customer}
                    CabinetrySave={setCabinetry}
                    kitchenMaterial={kitchenMaterial}
                />
            );
        } else if (kitchanId && kitchanId.id === 3) {
            return (<UshapeCabinetryForm
                steps={steps}
                cabinetryFinishes={cabinetryFinishes}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                Cabinetry={cabinetry}
                Customer={customer}
                CabinetrySave={setCabinetry}
                kitchenMaterial={kitchenMaterial}
            />);
        } else if (kitchanId && kitchanId.id === 4) {
            return (<IslandCabinetryForm
                steps={steps}
                cabinetryFinishes={cabinetryFinishes}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                Cabinetry={cabinetry}
                Customer={customer}
                CabinetrySave={setCabinetry}
                kitchenMaterial={kitchenMaterial}
            />);
        } else {
            return (
                <StraightCabinetryForm
                    steps={steps}
                    cabinetryFinishes={cabinetryFinishes}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    Cabinetry={cabinetry}
                    Customer={customer}
                    CabinetrySave={setCabinetry}
                    kitchenMaterial={kitchenMaterial}
                />
            );
        }
    }

    const AccessoriesFormDisplay = (kitchanId) => {
        if (kitchanId && kitchanId.id === 2) {
            return (
                <LshapeAccessoryForm
                    steps={steps}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    Accessorieslist={accessories}
                    Accessory={accessory}
                    setFinal={setFinal}
                    Kitchan={kitchanId}
                    Cabinetry={cabinetry}
                    handleSave={handleSave}
                />
            );
        } else if (kitchanId && kitchanId.id === 3) {
            return (<UshapeAccessoryForm
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                Accessorieslist={accessories}
                Accessory={accessory}
                setFinal={setFinal}
                Kitchan={kitchanId}
                Cabinetry={cabinetry}
                handleSave={handleSave}
            />);
        } else if (kitchanId && kitchanId.id === 4) {
            return (<IslandAccessoryForm
                steps={steps}
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                Accessorieslist={accessories}
                Accessory={accessory}
                setFinal={setFinal}
                Kitchan={kitchanId}
                Cabinetry={cabinetry}
                handleSave={handleSave}
            />);
        } else {
            return (
                <StraightAccessoryForm
                    steps={steps}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    Accessorieslist={accessories}
                    Accessory={accessory}
                    setFinal={setFinal}
                    Kitchan={kitchanId}
                    Cabinetry={cabinetry}
                    handleSave={handleSave}
                />
            );
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <React.Fragment>
                {
                    activeStep == 0
                        ?
                        <KitchenSelect
                            steps={steps}
                            kitchenlist={kitchenlist}
                            activeStep={activeStep}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            Customer={customer}
                            Kitchan={kitchan}
                            KitchanSave={setKitchan}
                        />
                        :
                        activeStep == 1
                            ?
                            CabinetryFormDisplay(kitchan)
                            :
                            activeStep == 2
                                ?
                                AccessoriesFormDisplay(kitchan)
                                :
                                <CalcluateForm
                                    steps={steps}
                                    activeStep={activeStep}
                                    handleBack={handleBack}
                                    handleNext={handleNext}
                                    Customer={customer}
                                    final={final}
                                    Cabinetry={cabinetry}
                                    Kitchan={kitchan}
                                />
                }
            </React.Fragment>
        </Box>
    );
}
