import React from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

export default function AccessoryTable({
    columnlist,
    datalist,
    ondelete
}) {
    return (
        <TableContainer component={Paper} style={{ maxHeight: 300 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                <TableHead>
                    <TableRow>
                        {columnlist.map((item, index) => {
                            return (
                                <TableCell align={index === 0 ? "" : "right"}>{item}</TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datalist ? datalist.map((row, index) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.item+" "+row.brand}
                            </TableCell>
                            <TableCell align="right">{row.quantity}</TableCell>
                            <TableCell align="right">{row.totalcost}</TableCell>
                            <TableCell align="right">
                                <IconButton aria-label="delete" size="large" onClick={() => ondelete({ item: row, index: index })}>
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )) : ""}
                </TableBody>
            </Table>
        </TableContainer>
    )
}