import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function Loft({
    loftFinish,
    editloft,
    handleSave
}
){
    let [dimension, setDimension] = React.useState({});
    useEffect(() => {
        DataProcessing(dimension);
    }, [dimension]);

    useEffect(() => {
        if(editloft){
            for ( var property in editloft ) {
            if(property === "dimensionA"){
                setDimension(prevState => ({ ...prevState, ["dimensionA"] : editloft.dimensionA }));
            } else if(property === "dimensionB"){
                setDimension(prevState => ({ ...prevState, ["dimensionB"] : editloft.dimensionB }));
            } else if(property === "dimensionC"){
                const finddata = loftFinish.findIndex(item => {return editloft.dimensionC.id === item.id});
                setDimension(prevState => ({ ...prevState, ['dimensionC'] : loftFinish[finddata] }));
            } else if(property === "dimensionD"){
                setDimension(prevState => ({ ...prevState, ["dimensionD"] : editloft.dimensionD }));
            } else if(property === "dimensionE"){
                setDimension(prevState => ({ ...prevState, ["dimensionE"] : editloft.dimensionE }));
            } else if(property === "dimensionF"){
                const finddata = loftFinish.findIndex(item => {return editloft.dimensionF.id === item.id});
                setDimension(prevState => ({ ...prevState, ['dimensionF'] : loftFinish[finddata] }));
            } else if(property === "checkedA"){
                setDimension(prevState => ({ ...prevState, ["checkedA"] : booleanIdentifier(editloft.checkedA) }));
            } else if(property === "checkedB"){
                setDimension(prevState => ({ ...prevState, ["checkedB"] : booleanIdentifier(editloft.checkedB) }));
            } else if(property === "sqftOfKitchenLoft"){
                setDimension(prevState => ({ ...prevState, ["sqftOfKitchenLoft"] : editloft.sqftOfKitchenLoft }));
            } else if(property === "TotalCostKitchenLoft"){
                setDimension(prevState => ({ ...prevState, ["TotalCostKitchenLoft"] : editloft.TotalCostKitchenLoft }));
            } else if(property === "sqftOfWardrobeLoft"){
                setDimension(prevState => ({ ...prevState, ["sqftOfWardrobeLoft"] : editloft.sqftOfWardrobeLoft }));
            } else if(property === "TotalCostWardrobeLoft"){
                setDimension(prevState => ({ ...prevState, ["TotalCostWardrobeLoft"] : editloft.TotalCostWardrobeLoft }));
            } else {

            }
        }
        }
    }, [])

    const booleanIdentifier = (data) => {
        if(data === '1' || data === 'true' || data === true){
            return true;
        }
        if(data === '0' || data === 'false' || data === false){
            return false;
        }
    }

    const DataProcessing = (data) => {
        KitchenLoft(data.dimensionA || 0, data.dimensionB || 0, data.dimensionC || loftFinish[0], data.checkedA || false);
        WardrobeLoft(data.dimensionD || 0, data.dimensionE || 0, data.dimensionF || loftFinish[0], data.checkedB || false);
    }

    const KitchenLoft = (dimensionA, dimensionB, dimensionC, status) => {
        if(status){
            setDimension(prevState => ({ ...prevState, ['sqftOfKitchenLoft']: parseFloat(((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2)) }));
            setDimension(prevState => ({ ...prevState, ['TotalCostKitchenLoft']: parseFloat(((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2)) * parseInt(dimensionC.price) }));
        }
    }

    const WardrobeLoft = (dimensionD, dimensionE, dimensionF, status) => {
        if(status){
            setDimension(prevState => ({ ...prevState, ['sqftOfWardrobeLoft']: parseFloat(((parseInt(dimensionD) * parseInt(dimensionE)) / 900).toFixed(2)) }));
            setDimension(prevState => ({ ...prevState, ['TotalCostWardrobeLoft']: parseFloat(((parseInt(dimensionD) * parseInt(dimensionE)) / 900).toFixed(2)) * parseInt(dimensionF.price) }));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave({
            name: "loft",
            value: dimension
        });
    }

    const handleChange = (e) => {
        if(e.target.name === 'checkedA' || e.target.name === 'checkedB'){
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        } else {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }

    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            mt={3}
        >
            <Grid container spacing={2}>
                <Grid container mt={2} xs={12} sm={8}>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                                Kitchen Loft
                                <Switch
                                    name='checkedA'
                                    checked={dimension.checkedA || false}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>
                            {dimension.checkedA ?
                            <Grid item xs={12} sm={12}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"93%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter Length of the Loft (in cm)"
                                    name="dimensionA"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionA || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"93%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter Height of the Loft (in cm)"
                                    name="dimensionB"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionB || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"93%"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Select the Finish of the Loft"
                                    value={dimension.dimensionC || loftFinish[0]}
                                    onChange={handleChange}
                                    name="dimensionC"
                                    helperText="Please Select your Finish"
                                    >
                                    {loftFinish.map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            </Grid>
                            : ""}

                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                                Wardrobe Loft
                                <Switch
                                    name='checkedB'
                                    checked={dimension.checkedB || false}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>
                            {dimension.checkedB ?
                            <Grid item xs={12} sm={12}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"93%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter Length of the Loft (in cm)"
                                    name="dimensionD"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionD || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"93%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter Height of the Loft (in cm)"
                                    name="dimensionE"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionE || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"93%"}>
                                <TextField
                                    select
                                    fullWidth
                                    label="Select the Finish of the Loft"
                                    value={dimension.dimensionF || loftFinish[0]}
                                    onChange={handleChange}
                                    name="dimensionF"
                                    helperText="Please Select your Finish"
                                    >
                                    {loftFinish.map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                        {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            </Grid>
                            : ""}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {dimension.checkedA
                                ?
                                <>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Sqft of Kitchen Loft:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.sqftOfKitchenLoft || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Rate of Kitchen Loft :
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.TotalCostKitchenLoft || 0.00}
                                    </TableCell>
                                </TableRow>
                                </>
                                : ""}
                                {dimension.checkedB
                                ?
                                <>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Sqft of Wardrobe Loft:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.sqftOfWardrobeLoft || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Rate of Wardrobe Loft :
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.TotalCostWardrobeLoft || 0.00}
                                    </TableCell>
                                </TableRow>
                                </>
                                : ""}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </Box>
        </Box>
    )
}