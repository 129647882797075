import React, {useEffect} from 'react';
import { Box } from '@mui/system';
import './../../../../assists/css/style.css';
import { Button } from '@mui/material';

export default function KitchenSelect({
        steps,
        activeStep,
        handleBack,
        handleNext,
        kitchenlist,
        KitchanSave,
        Kitchan
    }) {
    const [selectkitchan, setSelectKitchan] = React.useState(kitchenlist[0]);
    useEffect(() => {
        setSelectKitchan(Kitchan);
    }, [Kitchan])
    const handleChange = (item) => {
        setSelectKitchan(item);
        KitchanSave(item);
    }

    const OnSave = () => {
        handleNext();
    }

    return (
        <Box component={'div'} align="center" className='kitchentype'>
            <ul>
                {kitchenlist.map(item => {
                    if(item.id != "4"){
                        return (
                            <li><input type="radio" name="test" id={"cb1"+item.id} value={item.id} onChange={() => handleChange(item)} checked={selectkitchan.id === item.id ? true : false}/>
                                <label for={"cb1"+item.id}><img src={item.image} /></label>
                            </li>
                        )
                    }
                })}
            </ul>
            {selectkitchan ? selectkitchan.name : ""}

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button onClick={OnSave} variant="contained">
                    {activeStep === steps.length - 1 ? 'Finish' : 'Save and Next'}
                </Button>
            </Box>
        </Box>

    )
}

