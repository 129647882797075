import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function TVUnit({
    edittvunit,
    tvunit,
    handleSave
}) {
    let [dimension, setDimension] = React.useState({});
    useEffect(() => {
        DataProcessing(dimension);
    }, [dimension]);

    useEffect(() => {
        if (edittvunit) {
            DataFetching(edittvunit);
        }
    }, []);

    const DataFetching = (data) => {
        for ( var property in data ) {
            if(property === "dimensionA"){
                setDimension(prevState => ({ ...prevState, ["dimensionA"] : data.dimensionA }));
            } else if(property === "dimensionB"){
                setDimension(prevState => ({ ...prevState, ["dimensionB"] : data.dimensionB }));
            } else if(property === "dimensionC"){
                const finddata = tvunit['type'].findIndex(item => {return data.dimensionC.id === item.id});
                setDimension(prevState => ({ ...prevState, ["dimensionC"] : tvunit['type'][finddata] }));
            } else if(property === "dimensionG" && data.dimensionG){
                const finddata = tvunit['typecategory'].findIndex(item => {return data.dimensionG.id === item.id});
                setDimension(prevState => ({ ...prevState, ["dimensionG"] : tvunit['typecategory'][finddata] }));
            } else if(property === "dimensionD"){
                setDimension(prevState => ({ ...prevState, ["dimensionD"] : data.dimensionD }));
            } else if(property === "dimensionE"){
                setDimension(prevState => ({ ...prevState, ["dimensionE"] : data.dimensionE }));
            } else if(property === "CNC"){
                setDimension(prevState => ({ ...prevState, ["CNC"] : data.CNC }));
            } else if(property === "dimensionF"){
                const finddata = tvunit['baseCabinateFinish'].findIndex(item => {return data.dimensionF.id === item.id});
                setDimension(prevState => ({ ...prevState, ["dimensionF"] : tvunit['baseCabinateFinish'][finddata] }));
            } else if(property === "checkedA"){
                setDimension(prevState => ({ ...prevState, ["checkedA"] : booleanIdentifier(data.checkedA) }));
            } else if(property === "checkedB"){
                setDimension(prevState => ({ ...prevState, ["checkedB"] : booleanIdentifier(data.checkedB) }));
            } else {

            }
        }
    }

    const booleanIdentifier = (data) => {
        if(data === '1' || data === 'true' || data === true){
            return true;
        }
        if(data === '0' || data === 'false' || data === false){
            return false;
        }
    }

    const DataProcessing = (data) => {
        BackPanel(data.dimensionA || 0, data.dimensionB || 0, data.dimensionC || TypeCollection(dimension.dimensionG || tvunit['typecategory'][0])[0]);
        BaseCabinateFinish(data.dimensionD || 0, data.dimensionE || 0, data.dimensionF || tvunit['baseCabinateFinish'][0], data.checkedA);
        CNCWork(data.checkedB);
        TotalCost(data.backPanelRate || 0, data.BaseCabinateRate || 0, data.CNC || 0);
    }

    const TotalCost = (backPanelRate, BaseCabinateRate, CNC) => {
        setDimension(prevState => ({ ...prevState, ['total_cost']: parseFloat(backPanelRate) + parseFloat(BaseCabinateRate) + parseFloat(CNC) }));
    }

    const BackPanel = (dimensionA, dimensionB, dimensionC) => {
        setDimension(prevState => ({ ...prevState, ['backPanelSqft']: ((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['backPanelRate']: (parseFloat(((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2)) * parseInt(dimensionC['price'])).toFixed(2) }));
    }

    const CNCWork = (status) => {
        if(!status)
        {
            setDimension(prevState => ({ ...prevState, ['CNC']: 0 }));
        }
    }

    const BaseCabinateFinish = (dimensionD, dimensionE, dimensionF, status) => {
        if (status) {
            setDimension(prevState => ({ ...prevState, ['BaseCabinateSqft']: ((parseInt(dimensionD) * parseInt(dimensionE)) / 900).toFixed(2) }));
            setDimension(prevState => ({ ...prevState, ['BaseCabinateRate']: (parseFloat(((parseInt(dimensionD) * parseInt(dimensionE)) / 900).toFixed(2)) * parseInt(dimensionF['price'])).toFixed(2) }));
        } else {
            setDimension(prevState => ({ ...prevState, ['BaseCabinateSqft']: 0 }));
            setDimension(prevState => ({ ...prevState, ['BaseCabinateRate']: 0 }));
        }
    }

    const TypeCollection = (categoryid) => {
        return tvunit['type'].filter((item) => item.tvunittypecategory_id == categoryid.id ? item : null);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if(!dimension.dimensionC) {
            dimension.dimensionC = TypeCollection(dimension.dimensionG || tvunit['typecategory'][0])[0];
        }
        if(!dimension.dimensionG && dimension.dimensionC) {
            dimension.dimensionG = tvunit['typecategory'][0];
        }
        handleSave({
            name: "tvunit",
            value: dimension
        });
    }

    const handleChange = (e) => {
        if (e.target.name === "checkedA" || e.target.name === "checkedB") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        } else if(e.target.name === "dimensionG") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
            delete dimension.dimensionC;
        } else {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }

    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            mt={3}
        >
            <Grid container spacing={2}>
                <Grid container mt={2} xs={12} sm={8}>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the Length of the Back Panel (In cm)"
                                    name="dimensionA"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionA || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the Height of the Back Panel (In cm)"
                                    name="dimensionB"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionB || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    select
                                    label="Select the Material"
                                    fullWidth
                                    name='dimensionG'
                                    value={dimension.dimensionG || tvunit['typecategory'][0]}
                                    align="left"
                                    onChange={handleChange}
                                >
                                    {tvunit['typecategory'].map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    select
                                    label="Select the Type"
                                    fullWidth
                                    name='dimensionC'
                                    value={dimension.dimensionC || TypeCollection(dimension.dimensionG || tvunit['typecategory'][0])[0]}
                                    align="left"
                                    onChange={handleChange}
                                >
                                    {TypeCollection(dimension.dimensionG || tvunit['typecategory'][0]).map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                                Is there any Base cabinets
                                <Switch
                                    name='checkedA'
                                    checked={dimension.checkedA || false}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>
                            {dimension.checkedA ?
                                <Grid container xs={12} sm={12}>
                                    <Grid item xs={12} sm={12}>
                                        <Box component={'div'} mx={'auto'} width={"90%"} mb={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Enter the Length of the Base Cabinets (In cm)"
                                                autoFocus
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                type={'number'}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                name="dimensionD"
                                                value={dimension.dimensionD || 0}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box component={'div'} mx={'auto'} width={"90%"} mb={2}>
                                            <TextField
                                                required
                                                fullWidth
                                                label="Enter the Height of the Base Cabinets (In cm)"
                                                autoFocus
                                                type={'number'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{ inputProps: { min: 0 } }}
                                                name="dimensionE"
                                                value={dimension.dimensionE || 0}
                                                onChange={handleChange}
                                            />
                                        </Box>
                                        <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                            <TextField
                                                select
                                                label="Choose Finish of the Base Cabinets"
                                                fullWidth
                                                name='dimensionF'
                                                value={dimension.dimensionF || tvunit['baseCabinateFinish'][0]}
                                                align="left"
                                                onChange={handleChange}
                                            >
                                                {tvunit['baseCabinateFinish'].map((item) => (
                                                    <MenuItem key={item.id} value={item}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Box>
                                    </Grid>
                                </Grid>
                                : ""}
                        </Grid>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                                Is there any CNC work
                                <Switch
                                    name='checkedB'
                                    checked={dimension.checkedB || false}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>
                            {dimension.checkedB ?
                                <Grid container xs={12} sm={12}>
                                    <Box component={'div'} mx={'auto'} width={"90%"} mb={2}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Enter Rate of the CNC Work"
                                            autoFocus
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type={'number'}
                                            InputProps={{ inputProps: { min: 0 } }}
                                            name="CNC"
                                            value={dimension.CNC || 0}
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                : ""}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Back Panel Sqft:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.backPanelSqft || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Rate of the Back Panel:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.backPanelRate || 0.00}
                                    </TableCell>
                                </TableRow>
                                {dimension.checkedA ?
                                    <>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Base Cabinet Sqft:
                                            </TableCell>
                                            <TableCell align="right">
                                                ₹ {dimension.BaseCabinateSqft || 0.00}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                Rate of Base Cabinet:
                                            </TableCell>
                                            <TableCell align="right">
                                                ₹ {dimension.BaseCabinateRate || 0.00}
                                            </TableCell>
                                        </TableRow>
                                    </>
                                    : ""}
                                {dimension.checkedB ?
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            Rate of the CNC Work:
                                        </TableCell>
                                        <TableCell align="right">
                                            ₹ {dimension.CNC || 0.00}
                                        </TableCell>
                                    </TableRow>
                                    : ""}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </Box>
        </Box>
    )
}