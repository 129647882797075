import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function Discount({
    editdiscount,
    handleSave,
}) {
    let [dimension, setDimension] = React.useState({});
    useEffect(() => {
        if(editdiscount){
            setDimension(editdiscount);
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave({
            name: "discount",
            value: dimension
        });
    }

    const handleChange = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            mt={3}
        >
            <Grid container spacing={2}>
                <Grid container mt={2} xs={12} sm={8}>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={6} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Discount Amount"
                                    name="discount"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.discount || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Discount Cost:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.discount || 0.00}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </Box>
        </Box>
    )
}