import React, { useState, useEffect } from 'react';
import {
    Routes,
    Route,
} from 'react-router-dom';
import Login from '../views/Login';
import Header from './Header';
import Footer from './Footer';
import Dashboard from '../views/dashboard/Dashboard';
import { useNavigate } from 'react-router-dom';
import FormGrid from '../views/dashboard/FormComponent/FormGrid';
import Optionconfig from '../config/Optionconfig';
import ActionUrl from '../config/ActionUrl';

const Layout = () => {
    const navigate = useNavigate();
    let authData = localStorage.getItem('AuthData');
    let [loginUser, setLoginUser] = useState(authData ? true : false);
    const [logindetails, setLoginDetails] = useState(null);

    useEffect(() => {
        if (!authData) {
            navigate('/login');
        } else if (loginUser === false) {
            navigate('/login');
        } else {
            console.log("Login");
        }
        Optionconfig({
            method: 'GET',
            url: ActionUrl.user,
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                if(response){
                    setLoginDetails(response);
                }
            }
        });
    }, [loginUser, authData, navigate]);

    return (
        <>
            {authData || loginUser === true ? <Header signIn={setLoginUser} logindetails={logindetails}/> : null}
            <Routes>
                <Route exact path='/' element={<Dashboard signIn={setLoginUser}/>}></Route>
                <Route exact path='/login' element={<Login signIn={setLoginUser} />}></Route>
                <Route exact path='/quotation/create' element={<FormGrid signIn={setLoginUser}/>}></Route>
            </Routes>
            {authData || loginUser === true ? <Footer /> : null}
        </>
    )

}

export default Layout;