import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Optionconfig from '../../../config/Optionconfig';
import ActionUrl from '../../../config/ActionUrl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KitchanStepperTab from './kitchan/StepperTab';
import WorkAreaStepperTab from './workarea/StepperTab';
import WardrobeStepperTab from './wardrobe/Stepper';
import { useNavigate, useLocation } from 'react-router-dom';
import CounterTop from './countertop/CounterTop';
import Discount from './discount/Discount';
import { RotatingLines } from 'react-loader-spinner';
import Alert from '@mui/material/Alert';
import Appliance from './appliance/Appliance';
import Loft from './loft/Loft';
import OtherInteriors from './otherinteriors/OtherInteriors';
import TVUnit from './tvunit/TVUnit';
import Prayerunit from './prayerunit/Prayerunit';
import Otheritems from './otheritems/Otheritems';
import Shoerack from './shoerack/Shoerack';
import Crockeryunit from './crockeryunit/Crockeryunit';

export default function FormGrid() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [List, setList] = React.useState(null);
    const [customerDetails, setCustomerDetails] = React.useState({
        date: new Date(),
    });
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (Object.hasOwn(state, 'update') && Object.hasOwn(state, 'list')) {
            setCustomerDetails(state.update);
            setList(state.list);
        } else {
            setList(state);
        }
    }, []);

    const [error, setError] = React.useState({});

    const handleChange = (e) => {
        setCustomerDetails(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleValueChange = (data) => {
        setCustomerDetails(prevState => ({ ...prevState, [data.name]: data.value }));
    }

    const handleSubmit = () => {
        customerDetails.quotation_number = state.q_number;
        for (const property in customerDetails) {
            if ((property.search("_accordion") > 0) && (customerDetails[property] == false)) {
                delete customerDetails[property.split('_accordion')[0]];
                delete customerDetails[property];
            }
        }
        setLoading(true);
        Optionconfig({
            method: 'POST',
            url: ActionUrl.storequotation,
            body: { ...customerDetails },
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                setLoading(false);
                if (response.status === 'success') {
                    // console.log(response);
                    setCustomerDetails(prevState => ({ ...prevState, ['successmessage']: response.message }));
                    setError({});
                    navigate('/');
                } else {
                    setCustomerDetails(prevState => ({ ...prevState, ['successmessage']: null }));
                    setError(response);
                }
            }
        });
    }

    const handleDublicateSubmit = () => {
        for (const property in customerDetails) {
            if ((property.search("_accordion") > 0) && (customerDetails[property] == false)) {
                delete customerDetails[property.split('_accordion')[0]];
                delete customerDetails[property];
            }
        }

        customerDetails.dublicate = true;

        setLoading(true);
        Optionconfig({
            method: 'POST',
            url: ActionUrl.storequotation,
            body: { ...customerDetails },
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                setLoading(false);
                if (response.status === 'success') {
                    // console.log(response);
                    setCustomerDetails(prevState => ({ ...prevState, ['successmessage']: response.message }));
                    setError({});
                    navigate('/');
                } else {
                    setCustomerDetails(prevState => ({ ...prevState, ['successmessage']: null }));
                    setError(response);
                }
            }
        });
    }

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setCustomerDetails(prevState => ({ ...prevState, [panel]: isExpanded ? true : false }));
    }

    const handlePdfInvoice = (pdfpath) => {
        const link = document.createElement("a");
        link.download = 'QuotationPdf.pdf';
        link.href = pdfpath;
        link.setAttribute("target", "_blank");
        link.click();
        link.remove();
    }

    return (
        <Box component="form" noValidate sx={{ mt: 3 }} width={"80%"} m={'auto'}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <Box sx={{ m: "auto", mb: 2, textAlign: "center" }} component={'h2'}>
                        Create Quotation
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <TextField
                        fullWidth
                        label="Quotation No"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={state.q_number}
                        disabled={true}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        name="name"
                        required
                        fullWidth
                        id="name"
                        label="Customer Name"
                        autoFocus
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={customerDetails.name || ""}
                        onChange={handleChange}
                        error={error.name ? true : false}
                        helperText={error.name ? error.name : ""}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        fullWidth
                        name="city"
                        label="City"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                        value={customerDetails.city || ""}
                        error={error.city ? true : false}
                        helperText={error.city ? error.city : ""}
                    />
                </Grid>
                <Grid item md={6}>
                    <TextField
                        fullWidth
                        label="PIN"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={handleChange}
                        name="pin"
                        type="number"
                        value={customerDetails.pin || ""}
                        error={error.pin ? true : false}
                        helperText={error.pin ? error.pin : ""}
                    />
                </Grid>
                <Grid item md={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date"
                            name="date"
                            value={customerDetails.date}
                            onChange={(date) => handleValueChange({ name: "date", value: date })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            maxDate={new Date()}
                            renderInput={(params) =>
                                <TextField {...params}
                                    fullWidth
                                    error={error.date ? true : false}
                                    helperText={error.date ? error.date : ""}
                                />
                            }
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item md={6}>
                    <TextField
                        name="phone"
                        required
                        fullWidth
                        id="phone"
                        label="Customer Phone"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        type="number"
                        value={customerDetails.phone}
                        onChange={handleChange}
                        error={error.phone ? true : false}
                        helperText={error.phone ? error.phone : ""}
                    />
                </Grid>
                <Grid item md={12}>
                    <TextField
                        required
                        fullWidth
                        label="Customer Address"
                        name="address"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        multiline
                        rows={4}
                        value={customerDetails.address}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                        error={error.address ? true : false}
                        helperText={error.address ? error.address : ""}
                    />
                </Grid>
            </Grid>
            <Box sx={{ width: "100%" }}>
                <Accordion expanded={customerDetails.kitchan_accordion || false} onChange={handleAccordionChange('kitchan_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>
                            Kitchen Quotation
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <KitchanStepperTab {...List} handleSave={handleValueChange} kitchan_interior={customerDetails.kitchan_interior} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.workarea_accordion || false} onChange={handleAccordionChange('workarea_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>
                            Work Area Quotation
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <WorkAreaStepperTab {...List} handleSave={handleValueChange} workarea_interior={customerDetails.workarea_interior} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.wardrobe_accordion || false} onChange={handleAccordionChange('wardrobe_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography>Wardrobe</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <WardrobeStepperTab {...List} handleSave={handleValueChange} editwardrobe={customerDetails.wardrobe} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.loft_accordion || false} onChange={handleAccordionChange('loft_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Kitchen/Wardrobe Loft</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <Loft {...List} handleSave={handleValueChange} editloft={customerDetails.loft} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.appliance_accordion || false} onChange={handleAccordionChange('appliance_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Appliance</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <Appliance {...List} handleSave={handleValueChange} editappliance={customerDetails.appliance} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.tvunit_accordion || false} onChange={handleAccordionChange('tvunit_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>TV Unit</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <TVUnit {...List} handleSave={handleValueChange} edittvunit={customerDetails.tvunit} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.prayerunit_accordion || false} onChange={handleAccordionChange('prayerunit_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Prayer Unit</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <Prayerunit {...List} handleSave={handleValueChange} editprayerunit={customerDetails.prayerunit} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.crockeryunit_accordion || false} onChange={handleAccordionChange('crockeryunit_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Crockery Unit</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <Crockeryunit {...List} handleSave={handleValueChange} editcrockeryunit={customerDetails.crockeryunit} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.shoerack_accordion || false} onChange={handleAccordionChange('shoerack_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Shoe Rack</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <Shoerack {...List} handleSave={handleValueChange} shoerackitems={customerDetails.shoerack} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.countertop_accordion || false} onChange={handleAccordionChange('countertop_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Counter Top</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <CounterTop {...List} handleSave={handleValueChange} editcountertop={customerDetails.countertop} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.otherinteriors_accordion || false} onChange={handleAccordionChange('otherinteriors_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Other Interiors</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <OtherInteriors {...List} handleSave={handleValueChange} editotherinteriors={customerDetails.otherinteriors} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.otheritems_accordion || false} onChange={handleAccordionChange('otheritems_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Other Items</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <Otheritems {...List} handleSave={handleValueChange} editotheritems={customerDetails.otheritems} /> : ""}
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={customerDetails.discount_accordion || false} onChange={handleAccordionChange('discount_accordion')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel3a-content"
                        id="panel3a-header"
                    >
                        <Typography>Discount</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {List ? <Discount {...List} handleSave={handleValueChange} editdiscount={customerDetails.discount} /> : ""}
                    </AccordionDetails>
                </Accordion>
            </Box>
            <RotatingLines
                color="#4fa94d"
                width="100"
                visible={loading}
                ariaLabel='falling-lines-loading'
            />
            {error.messagerror ? error.messagerror.map(item => <Alert severity="error">{item}</Alert>) : ""}
            {customerDetails.successmessage ? <Alert severity="success">{customerDetails.successmessage}</Alert> : ""}
            <Grid md={12} className="bottomButtons">
                <Button variant="contained" onClick={handleSubmit}>
                    Save
                </Button>
                {customerDetails.pdfpath ?
                    <>
                        <Button variant="contained" onClick={handleDublicateSubmit}>
                            Dublicate Record
                        </Button>
                        <Button variant="contained" onClick={() => handlePdfInvoice(customerDetails.pdfpath)} color="secondary">
                            Download PDF Invoice
                        </Button>
                    </>
                    : ""}
            </Grid>
        </Box>
    )
}