import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import WardrobeSelect from './WardrobeSelect';
import WardrobeFormOne from './wardrobe/one/WardrobeForm';
import WardrobeFormTwo from './wardrobe/two/WardrobeForm';
import WardrobeFormThree from './wardrobe/three/WardrobeForm';
import WardrobeFormFour from './wardrobe/four/WardrobeForm';
import WardrobeFormFive from './wardrobe/five/WardrobeForm';
import AccessoryForm from './AccessoryForm';
import CalcluateForm from './CalculateForm';

export default function WardrobeStepperTab({ handleSave, q_number, wardrobe, accessories, editwardrobe }) {
    const steps = ['Type of Wardrobe', 'Wardrobe Calc', 'Accessories Calc', 'Total Wardrobe Cost'];
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [selectWardrobe, setSelectWardrobe] = React.useState({no_of_wardrobes: '1'});
    const [WardrobeForm, setWardrobeForm] = React.useState(null);
    const [accessory, setAccessory] = React.useState([]);
    const [final,setFinal] = React.useState(null);

    React.useEffect(() => {
        if(editwardrobe){
            setSelectWardrobe({
                no_of_wardrobes: editwardrobe.no_of_wardrobes
            });
            setWardrobeForm(editwardrobe.wardrobeform);
            setAccessory(editwardrobe.accessory);
            setFinal(editwardrobe.final);
        }
    }, [editwardrobe])

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
        if (activeStep === steps.length - 1) {
            // handleClose(false);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const WardrobeFormTab = (selectWardrobe) => {
        if(selectWardrobe === "1"){
            return (
                <WardrobeFormOne
                    steps={steps}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    wardrobe={wardrobe}
                    setWardrobeForm={setWardrobeForm}
                    WardrobeForm={WardrobeForm}
                />
            )
        } else if (selectWardrobe === "2"){
            return (
                <WardrobeFormTwo
                    steps={steps}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    wardrobe={wardrobe}
                    setWardrobeForm={setWardrobeForm}
                    WardrobeForm={WardrobeForm}
                />
            )
        } else if (selectWardrobe === "3"){
            return (
                <WardrobeFormThree
                    steps={steps}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    wardrobe={wardrobe}
                    setWardrobeForm={setWardrobeForm}
                    WardrobeForm={WardrobeForm}
                />
            )
        } else if (selectWardrobe === "4"){
            return (
                <WardrobeFormFour
                    steps={steps}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    wardrobe={wardrobe}
                    setWardrobeForm={setWardrobeForm}
                    WardrobeForm={WardrobeForm}
                />
            )
        } else {
            return (
                <WardrobeFormFive
                    steps={steps}
                    activeStep={activeStep}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    wardrobe={wardrobe}
                    setWardrobeForm={setWardrobeForm}
                    WardrobeForm={WardrobeForm}
                />
            )
        }
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};

                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <React.Fragment>
                {
                    activeStep == 0
                        ?
                        <WardrobeSelect
                            steps={steps}
                            activeStep={activeStep}
                            handleBack={handleBack}
                            handleNext={handleNext}
                            selectWardrobe={selectWardrobe}
                            setSelectWardrobe={setSelectWardrobe}
                        />
                        :
                        activeStep == 1
                            ?
                            WardrobeFormTab(selectWardrobe.no_of_wardrobes)
                            :
                            activeStep == 2
                            ?
                            <AccessoryForm
                                steps={steps}
                                activeStep={activeStep}
                                handleBack={handleBack}
                                handleNext={handleNext}
                                Accessorieslist={accessories}
                                selectWardrobe={selectWardrobe}
                                WardrobeForm={WardrobeForm}
                                Accessory={accessory}
                                setAccessoryList={setAccessory}
                                setFinal={setFinal}
                                handleSave={handleSave}
                            />
                            :
                                <CalcluateForm
                                    steps={steps}
                                    activeStep={activeStep}
                                    handleBack={handleBack}
                                    handleNext={handleNext}
                                    final={final}
                                />
                }

            </React.Fragment>
        </Box>
    );
}
