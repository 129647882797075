import React from 'react';
import { Box } from '@mui/system';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function WardrobeFormFour({
    steps,
    activeStep,
    handleBack,
    handleNext,
    WardrobeForm,
    setWardrobeForm,
    wardrobe
}) {
    let [dimension, setDimension] = React.useState({});

    let removeobjectlist = [
        'width5',
        'height5',
        'sqfofwardrobe5',
        'widthandheight5',
        'finish5',
        'material5'
    ];

    React.useEffect(() => {
        SqftofWardrobe(
            dimension.width1 || 0, dimension.height1 || 0,
            dimension.width2 || 0, dimension.height2 || 0,
            dimension.width3 || 0, dimension.height3 || 0,
            dimension.width4 || 0, dimension.height4 || 0
        );
        TotalCost(
            dimension.sqfofwardrobe1 || 0,
            dimension.sqfofwardrobe2 || 0,
            dimension.sqfofwardrobe3 || 0,
            dimension.sqfofwardrobe4 || 0,
            dimension.finish1 || FinishesFilter(dimension.material1 || "1")[0],
            dimension.finish2 || FinishesFilter(dimension.material2 || "1")[0],
            dimension.finish3 || FinishesFilter(dimension.material3 || "1")[0],
            dimension.finish4 || FinishesFilter(dimension.material4 || "1")[0],
        );
    }, [dimension]);

    React.useEffect(() => {
        if (WardrobeForm) {
            ObjectRemoveMethod(WardrobeForm, removeobjectlist);
            setDimension(WardrobeForm);
        }
    }, []);

    const ObjectRemoveMethod = (dimension, arraylist) => {
        arraylist.forEach(element => {
            if (Object.hasOwn(dimension, element)) {
                delete dimension[element];
            }
        });
    }

    const SqftofWardrobe = (width1, height1, width2, height2, width3, height3, width4, height4) => {
        setDimension(prevState => ({ ...prevState, ['sqfofwardrobe1']: parseFloat((width1 * height1) / 900).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['widthandheight1']: width1 + " X " + height1 }));
        setDimension(prevState => ({ ...prevState, ['sqfofwardrobe2']: parseFloat((width2 * height2) / 900).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['widthandheight2']: width2 + " X " + height2 }));
        setDimension(prevState => ({ ...prevState, ['sqfofwardrobe3']: parseFloat((width3 * height3) / 900).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['widthandheight3']: width3 + " X " + height3 }));
        setDimension(prevState => ({ ...prevState, ['sqfofwardrobe4']: parseFloat((width4 * height4) / 900).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['widthandheight4']: width4 + " X " + height4 }));
    }

    const TotalCost = (
        sqfofwardrobe1,
        sqfofwardrobe2,
        sqfofwardrobe3,
        sqfofwardrobe4,
        finish1,
        finish2,
        finish3,
        finish4,
    ) => {
        if (sqfofwardrobe1 > 0 && sqfofwardrobe2 > 0 && sqfofwardrobe3 > 0 && sqfofwardrobe4 > 0) {
            setDimension(prevState => ({ ...prevState, ['totalcost']: (((parseFloat(sqfofwardrobe1) * parseInt(finish1.price)) + (parseFloat(sqfofwardrobe2) * parseInt(finish2.price)) + (parseFloat(sqfofwardrobe3) * parseInt(finish3.price)) + (parseFloat(sqfofwardrobe4) * parseInt(finish4.price)))).toFixed(2) }));
        } else {
            setDimension(prevState => ({ ...prevState, ['totalcost']: 0 }));
        }
    }

    const FinishesFilter = (id) => {
        return wardrobe.wardrobeFinish.filter((item) => parseInt(id) === parseInt(item.wardrobe_material_id));
    }

    const handleChange = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    const handleChangeMaterial = (e) => {
        if (e.target.name === "finish1") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
            FinishesFilter(e.target.value);
            setDimension(prevState => ({ ...prevState, ['finish1']: FinishesFilter(e.target.value)[0] }));
        } else if (e.target.name === "finish2") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
            FinishesFilter(e.target.value);
            setDimension(prevState => ({ ...prevState, ['finish2']: FinishesFilter(e.target.value)[0] }));
        } else if (e.target.name === "finish3") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
            FinishesFilter(e.target.value);
            setDimension(prevState => ({ ...prevState, ['finish3']: FinishesFilter(e.target.value)[0] }));
        } else {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
            FinishesFilter(e.target.value);
            setDimension(prevState => ({ ...prevState, ['finish4']: FinishesFilter(e.target.value)[0] }));
        }
    }

    const handleChangeMaterial1 = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        FinishesFilter(e.target.value);
        setDimension(prevState => ({ ...prevState, ['finish1']: FinishesFilter(e.target.value)[0] }));
    }

    const handleChangeMaterial2 = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        FinishesFilter(e.target.value);
        setDimension(prevState => ({ ...prevState, ['finish2']: FinishesFilter(e.target.value)[0] }));
    }

    const handleChangeMaterial3 = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        FinishesFilter(e.target.value);
        setDimension(prevState => ({ ...prevState, ['finish3']: FinishesFilter(e.target.value)[0] }));
    }

    const handleChangeMaterial4 = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        FinishesFilter(e.target.value);
        setDimension(prevState => ({ ...prevState, ['finish4']: FinishesFilter(e.target.value)[0] }));
    }

    const onSave = () => {
        setWardrobeForm(dimension);
        handleNext();
    }

    return (
        <Box component={'div'} my={2}>
            <Grid container style={{ height: "400px", overflow: "auto" }}>
                <Grid container sm={8}>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                required
                                label="Enter the Dimension First Wardrobe (In cm)"
                                name={'width1'}
                                type={'number'}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dimension["width1"] || 0}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                required
                                label="Enter the Height First Wardrobe (In cm)"
                                name={'height1'}
                                type={'number'}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dimension["height1"] || 0}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                select
                                name="material1"
                                label="Choose the Material for the wardrobe"
                                value={dimension.material1 || "1"}
                                fullWidth
                                onChange={handleChangeMaterial1}
                            >
                                {wardrobe.material.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                select
                                label="Choose the Finish for the wardrobe"
                                name="finish1"
                                value={dimension.finish1 || FinishesFilter(dimension.material1 || "1")[0]}
                                onChange={handleChange}
                                fullWidth
                            >
                                {FinishesFilter(dimension.material1 || "1").map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                required
                                label="Enter the Dimension Second Wardrobe (In cm)"
                                name={'width2'}
                                type={'number'}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dimension["width2"] || 0}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                required
                                label="Enter the Height Second Wardrobe (In cm)"
                                name={'height2'}
                                type={'number'}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dimension["height2"] || 0}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                select
                                name="material2"
                                label="Choose the Material for the wardrobe"
                                value={dimension.material2 || "1"}
                                fullWidth
                                onChange={handleChangeMaterial2}
                            >
                                {wardrobe.material.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                select
                                label="Choose the Finish for the wardrobe"
                                name="finish2"
                                value={dimension.finish2 || FinishesFilter(dimension.material2 || "1")[0]}
                                onChange={handleChange}
                                fullWidth
                            >
                                {FinishesFilter(dimension.material2 || "1").map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                required
                                label="Enter the Dimension Third Wardrobe (In cm)"
                                name={'width3'}
                                type={'number'}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dimension["width3"] || 0}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                required
                                label="Enter the Height Third Wardrobe (In cm)"
                                name={'height3'}
                                type={'number'}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dimension["height3"] || 0}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                select
                                name="material3"
                                label="Choose the Material for the wardrobe"
                                value={dimension.material3 || "1"}
                                fullWidth
                                onChange={handleChangeMaterial3}
                            >
                                {wardrobe.material.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                select
                                label="Choose the Finish for the wardrobe"
                                name="finish3"
                                value={dimension.finish3 || FinishesFilter(dimension.material3 || "1")[0]}
                                onChange={handleChange}
                                fullWidth
                            >
                                {FinishesFilter(dimension.material3 || "1").map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                required
                                label="Enter the Dimension Fourth Wardrobe (In cm)"
                                name={'width4'}
                                type={'number'}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dimension["width4"] || 0}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                required
                                label="Enter the Height Fourth Wardrobe (In cm)"
                                name={'height4'}
                                type={'number'}
                                fullWidth
                                InputProps={{ inputProps: { min: 0 } }}
                                value={dimension["height4"] || 0}
                                onChange={(e) => handleChange(e)}
                            />
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                select
                                name="material4"
                                label="Choose the Material for the wardrobe"
                                value={dimension.material4 || "1"}
                                fullWidth
                                onChange={handleChangeMaterial4}
                            >
                                {wardrobe.material.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box component={'div'} mx={'auto'} m={1}>
                            <TextField
                                select
                                label="Choose the Finish for the wardrobe"
                                name="finish4"
                                value={dimension.finish4 || FinishesFilter(dimension.material4 || "1")[0]}
                                onChange={handleChange}
                                fullWidth
                            >
                                {FinishesFilter(dimension.material4 || "1").map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                    </Grid>
                </Grid>
                <Grid sm={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total Area of the First Wardrobe:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.widthandheight1 || 0 + " X " + 0}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total Area of the Second Wardrobe:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.widthandheight2 || 0 + " X " + 0}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total Area of the Third Wardrobe:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.widthandheight3 || 0 + " X " + 0}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total Area of the Fourth Wardrobe:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.widthandheight4 || 0 + " X " + 0}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Sqft of First Wardrobe:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.sqfofwardrobe1 || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Sqft of Second Wardrobe:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.sqfofwardrobe2 || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Sqft of Third Wardrobe:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.sqfofwardrobe3 || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Sqft of Fourth Wardrobe:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.sqfofwardrobe4 || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Total Wardrobe Cost:
                                    </TableCell>
                                    <TableCell align="right">
                                        {dimension.totalcost || 0.00}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button onClick={onSave} variant="contained">
                    {activeStep === steps.length - 1 ? 'Finish' : 'Save and Next'}
                </Button>
            </Box>
        </Box>
    )
}

