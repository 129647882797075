import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function StraightCabinetryForm({
    steps,
    activeStep,
    handleBack,
    handleNext,
    cabinetryFinishes,
    Cabinetry,
    CabinetrySave,
    kitchenMaterial,
    Customer
}) {
    let [dimension, setDimension] = React.useState({});

    const [error, setError] = React.useState({
        basewallA: null,
        dimensionC: null,
        dimensionD: null,
        dimensionE: null,
        dimensionF: null,
    });

    useEffect(() => {
        DataProcess(dimension)
    }, [dimension]);

    useEffect(() => {
        if(Cabinetry){
            DataFetching(Cabinetry);
        }
    },[])

    const DataFetching = (data) => {
        for ( var property in data ) {
            if(property === "dimensionB"){
                const finddata = cabinetryFinishes.findIndex(item => {return data.dimensionB.id === item.id});
                setDimension(prevState => ({ ...prevState, ['dimensionB'] : cabinetryFinishes[finddata] }));
            } else if(property === "dimensionI"){
                const finddata = cabinetryFinishes.findIndex(item => {return data.dimensionI.id === item.id});
                setDimension(prevState => ({ ...prevState, ['dimensionI'] : cabinetryFinishes[finddata] }));
            } else if(property === "base_material_id"){
                const finddata = kitchenMaterial['baseCabinateMaterial'].findIndex(item => {return data.base_material_id.id === item.id});
                setDimension(prevState => ({ ...prevState, ['base_material_id'] : kitchenMaterial['baseCabinateMaterial'][finddata] }));
            } else if(property === "wall_material_id"){
                const finddata = kitchenMaterial['wallCabinateMaterial'].findIndex(item => {return data.wall_material_id.id === item.id});
                setDimension(prevState => ({ ...prevState, ['wall_material_id'] : kitchenMaterial['wallCabinateMaterial'][finddata] }));
            } else if(property === "tall_material_id"){
                const finddata = kitchenMaterial['tallCabinateMaterial'].findIndex(item => {return data.tall_material_id.id === item.id});
                setDimension(prevState => ({ ...prevState, ['tall_material_id'] : kitchenMaterial['tallCabinateMaterial'][finddata] }));
            } else if(property === "island_material_id"){
                const finddata = kitchenMaterial['islandCabinateMaterial'].findIndex(item => {return data.island_material_id.id === item.id});
                setDimension(prevState => ({ ...prevState, ['island_material_id'] : kitchenMaterial['islandCabinateMaterial'][finddata] }));
            } else if(property === "basewallA"){
                setDimension(prevState => ({ ...prevState, ["basewallA"] : data.basewallA }));
            } else if(property === "dimensionD"){
                setDimension(prevState => ({ ...prevState, ["dimensionD"] : data.dimensionD }));
            } else if(property === "dimensionE"){
                setDimension(prevState => ({ ...prevState, ["dimensionE"] : data.dimensionE }));
            } else if(property === "dimensionC"){
                if(booleanIdentifier(data.checkedA)){
                    setDimension(prevState => ({ ...prevState, ["dimensionC"] : data.dimensionC }));
                } else {
                    setDimension(prevState => ({ ...prevState, ["dimensionC"] : 0 }));
                }
            } else if(property === "dimensionF"){
                if(booleanIdentifier(data.checkedB)){
                    setDimension(prevState => ({ ...prevState, ["dimensionF"] : data.dimensionF }));
                } else {
                    setDimension(prevState => ({ ...prevState, ["dimensionF"] : 0 }));
                }
            } else if(property === "dimensionJ"){
                if(booleanIdentifier(data.checkedC)){
                    setDimension(prevState => ({ ...prevState, ["dimensionJ"] : data.dimensionJ }));
                } else {
                    setDimension(prevState => ({ ...prevState, ["dimensionJ"] : 0 }));
                }
            } else if(property === "dimensionS"){
                if(booleanIdentifier(data.checkedC)){
                    setDimension(prevState => ({ ...prevState, ["dimensionS"] : data.dimensionS }));
                } else {
                    setDimension(prevState => ({ ...prevState, ["dimensionS"] : 0 }));
                }
            } else if(property === "dimensionL"){
                const finddata = cabinetryFinishes.findIndex(item => {return data.dimensionL.id === item.id});
                setDimension(prevState => ({ ...prevState, ["dimensionL"] : cabinetryFinishes[finddata] }));
            } else if(property === "dimensionT"){
                setDimension(prevState => ({ ...prevState, ["dimensionT"] : data.dimensionT }));
            } else if(property === "dimensionU"){
                setDimension(prevState => ({ ...prevState, ["dimensionU"] : data.dimensionU }));
            } else if(property === "cabinatry_cost") {
                setDimension(prevState => ({ ...prevState, ["cabinatry_cost"] : data.cabinatry_cost }));
            } else if(property === "sqftOfBase"){
                setDimension(prevState => ({ ...prevState, ["sqftOfBase"] : data.sqftOfBase }));
            } else if(property === "sqftOfWall"){
                setDimension(prevState => ({ ...prevState, ["sqftOfWall"] : data.sqftOfWall }));
            } else if(property === "sqftOfTall"){
                setDimension(prevState => ({ ...prevState, ["sqftOfTall"] : data.sqftOfTall }));
            } else if(property === "sqftOfIsland"){
                setDimension(prevState => ({ ...prevState, ["sqftOfIsland"] : data.sqftOfIsland }));
            } else if(property === "checkedA"){
                setDimension(prevState => ({ ...prevState, ["checkedA"] : booleanIdentifier(data.checkedA) }));
            } else if(property === "checkedB"){
                setDimension(prevState => ({ ...prevState, ["checkedB"] : booleanIdentifier(data.checkedB) }));
            } else if(property === "checkedC"){
                setDimension(prevState => ({ ...prevState, ["checkedC"] : booleanIdentifier(data.checkedC) }));
            } else if(property === "checkedD"){
                setDimension(prevState => ({ ...prevState, ["checkedD"] : booleanIdentifier(data.checkedD) }));
            } else if(property === "cabinatry_cost"){
                setDimension(prevState => ({ ...prevState, ["cabinatry_cost"] : data.cabinatry_cost }));
            } else {

            }
        }
    }

    const booleanIdentifier = (data) => {
        if(data === '1' || data === 'true' || data === true){
            return true;
        }
        if(data === '0' || data === 'false' || data === false){
            return false;
        }
    }


    const DataProcess = (dimension) => {
        setDimension(prevState => ({ ...prevState, ['sqftOfBase']: sqftOfBase(dimension).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['sqftOfWall']: sqftOfWall(dimension).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['sqftOfTall']: sqftOfTall(dimension).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['sqftOfIsland']: sqftOfIsland(dimension).toFixed(2) }));
        setDimension(prevState => ({ ...prevState, ['cabinatry_cost']: TotalCobinate(dimension).toFixed(2) }));
    }


    const sqftOfBase = (dimension) => {
        return (((parseInt(dimension.basewallA || 0)) - parseInt((dimension.checkedB && parseInt(dimension.dimensionF) > 0) ? parseInt(dimension.dimensionF) : 0) - parseInt((dimension.checkedC && parseInt(dimension.dimensionJ)) ? parseInt(dimension.dimensionJ) : 0)) * parseInt(dimension.dimensionD || 0)) / 900;
    }

    const sqftOfWall = (dimension) => {
        return (((parseInt(dimension.basewallA || 0)) - parseInt((dimension.checkedA && parseInt(dimension.dimensionC) > 0) ? parseInt(dimension.dimensionC) : 0) - parseInt((dimension.checkedC && parseInt(dimension.dimensionJ)) ? parseInt(dimension.dimensionJ) : 0)) * parseInt(dimension.dimensionE || 0)) / 900;
    }

    const sqftOfTall = (dimension) => {
        return dimension.checkedC ? (parseInt(parseInt(dimension.dimensionJ || 0) * parseInt(dimension.dimensionS || 0)) / 900) : 0;
    }

    const sqftOfIsland = (dimension) => {
        return dimension.checkedD ? (parseInt(parseInt(dimension.dimensionT || 0) * parseInt(dimension.dimensionU || 0)) / 900) : 0;
    }

    const TotalCobinate = (dimension) => {
        let sqftOfBasePrice = dimension.hasOwnProperty('sqftOfBase') ? parseFloat(dimension.sqftOfBase) * parseFloat(dimension.dimensionB ? parseFloat(dimension.dimensionB.price) : parseFloat(cabinetryFinishes[0].price)) : 0;
        let sqftOfWallPrice = dimension.hasOwnProperty('sqftOfWall') ? parseFloat(dimension.sqftOfWall) * parseFloat(dimension.dimensionI ? parseFloat(dimension.dimensionI.price) : parseFloat(cabinetryFinishes[0].price)) : 0;
        let sqftOfTallPrice = dimension.hasOwnProperty('sqftOfTall') ? parseFloat(dimension.sqftOfTall) * parseFloat(dimension.dimensionL ? parseFloat(dimension.dimensionL.price) : parseFloat(cabinetryFinishes[0].price)) : 0;
        let sqftOfIslandPrice = dimension.hasOwnProperty('sqftOfIsland') ? parseFloat(dimension.sqftOfIsland) * parseFloat(dimension.dimensionB ? parseFloat(dimension.dimensionB.price) : parseFloat(cabinetryFinishes[0].price)) * 1.25 : 0;
        return parseFloat(sqftOfBasePrice) + parseFloat(sqftOfWallPrice) + parseFloat(sqftOfTallPrice) + parseFloat(sqftOfIslandPrice);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        CabinetrySave(dimension);
        handleNext();
    }

    const handleChange = (e) => {
        if(e.target.name === "checkedB" || e.target.name === "checkedA" || e.target.name === "checkedC" || e.target.name === "checkedD") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        } else {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }

    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            mt={3}
        >
            <Grid container spacing={2}>
                <Grid container mt={2} xs={12} sm={8}>
                    <Grid container xs={12} sm={8}>
                        <Grid item xs={12} sm={12}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter Dimensions of Wall (In cm)"
                                    name="basewallA"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.basewallA || 0}
                                    onChange={handleChange}
                                    error={error.basewallA ? true : false}
                                    helperText={error.basewallA ? error.basewallA : ""}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} mb={2}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the height of the Base cabinets (In cm)"
                                    autoFocus
                                    name="dimensionD"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionD || 0}
                                    onChange={handleChange}
                                    error={error.dimensionD ? true : false}
                                    helperText={error.dimensionD ? error.dimensionD : ""}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} width={"90%"} mb={2}>
                                <TextField
                                    select
                                    label="Choose the Base Finish"
                                    fullWidth
                                    name='dimensionB'
                                    value={dimension.dimensionB || cabinetryFinishes[0]}
                                    align="left"
                                    onChange={handleChange}
                                    helperText=""
                                >
                                    {cabinetryFinishes.map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box component={'div'} mx={'auto'} width={"90%"}>
                                <TextField
                                    select
                                    label="Choose the Base Material"
                                    fullWidth
                                    name='base_material_id'
                                    value={dimension.base_material_id || kitchenMaterial['baseCabinateMaterial'][0]}
                                    align="left"
                                    onChange={handleChange}
                                    helperText=""
                                >
                                    {kitchenMaterial['baseCabinateMaterial'].map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the height of the Wall cabinets (In cm)"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    name="dimensionE"
                                    value={dimension.dimensionE || 0}
                                    onChange={handleChange}
                                    error={error.dimensionE ? true : false}
                                    helperText={error.dimensionE ? error.dimensionE : ""}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    select
                                    label="Choose the Wall Finish"
                                    fullWidth
                                    name="dimensionI"
                                    value={dimension.dimensionI || cabinetryFinishes[0]}
                                    align="left"
                                    onChange={handleChange}
                                    helperText=""
                                >
                                    {cabinetryFinishes.map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box component={'div'} mx={'auto'} width={"90%"}>
                                <TextField
                                    select
                                    label="Choose the Wall Material"
                                    fullWidth
                                    name='wall_material_id'
                                    value={dimension.wall_material_id || kitchenMaterial['wallCabinateMaterial'][0]}
                                    align="left"
                                    onChange={handleChange}
                                    helperText=""
                                >
                                    {kitchenMaterial['wallCabinateMaterial'].map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                                Enter length where Base Cabinets is not required
                                <Switch
                                    name='checkedB'
                                    checked={dimension.checkedB || false}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>
                            {dimension.checkedB ?
                                <Box component={'div'} mx={'auto'} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        type={'number'}
                                        label="Enter the width that getting occupied (In cm)"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        name="dimensionF"
                                        value={dimension.dimensionF || 0}
                                        onChange={handleChange}
                                        error={error.dimensionF ? true : false}
                                        helperText={error.dimensionF ? error.dimensionF : ""}
                                    />
                                </Box>
                                : ""}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                            Enter length where Wall Cabinets is not required
                                <Switch
                                    name='checkedA'
                                    checked={dimension.checkedA || false}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </Box>
                            {dimension.checkedA ?
                                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        type={'number'}
                                        label="Please enter the dimension that's occupying (In cm)"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        name="dimensionC"
                                        value={dimension.dimensionC || 0}
                                        onChange={handleChange}
                                        error={error.dimensionC ? true : false}
                                        helperText={error.dimensionC ? error.dimensionC : ""}
                                    />
                                </Box>
                                : ""}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                            Do you need a Tall unit?
                            <Switch
                                name='checkedC'
                                checked={dimension.checkedC || false}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Box>
                        {dimension.checkedC ?
                            <>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Please enter the dimension that's occupying (In cm)"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    name="dimensionJ"
                                    value={dimension.dimensionJ || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Please enter the height of the tall unit (In cm)"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    name="dimensionS"
                                    value={dimension.dimensionS || 0}
                                    onChange={handleChange}
                                />
                            </Box>

                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                            <TextField
                                select
                                label="Choose the Tall Finish"
                                fullWidth
                                name="dimensionL"
                                value={dimension.dimensionL || cabinetryFinishes[0]}
                                align="left"
                                onChange={handleChange}
                                helperText=""
                            >
                                {cabinetryFinishes.map((item) => (
                                    <MenuItem key={item.id} value={item}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    select
                                    label="Choose the Tall Material"
                                    fullWidth
                                    name='tall_material_id'
                                    value={dimension.tall_material_id || kitchenMaterial['tallCabinateMaterial'][0]}
                                    align="left"
                                    onChange={handleChange}
                                    helperText=""
                                >
                                    {kitchenMaterial['tallCabinateMaterial'].map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            </>
                            : ""}
                        <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                            Any need for an island
                            <Switch
                                name='checkedD'
                                checked={dimension.checkedD || false}
                                onChange={handleChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </Box>
                        {dimension.checkedD ?
                            <>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Please enter the length of the island"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    name="dimensionT"
                                    value={dimension.dimensionT || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Please enter the height of the island (In cm)"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    name="dimensionU"
                                    value={dimension.dimensionU || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                <TextField
                                    select
                                    label="Choose the Island Material"
                                    fullWidth
                                    name='island_material_id'
                                    value={dimension.island_material_id || kitchenMaterial['islandCabinateMaterial'][0]}
                                    align="left"
                                    onChange={handleChange}
                                    helperText=""
                                >
                                    {kitchenMaterial['islandCabinateMaterial'].map((item) => (
                                        <MenuItem key={item.id} value={item}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            </>
                            : ""}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Sqft of Base:
                                </TableCell>
                                <TableCell align="right">
                                    {parseFloat(dimension.sqftOfBase).toFixed(2) || 0.00}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Sqft of Wall:
                                </TableCell>
                                <TableCell align="right">
                                    {parseFloat(dimension.sqftOfWall).toFixed(2) || 0.00}
                                </TableCell>
                            </TableRow>
                            {dimension.checkedC ?
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Sqft of Tall
                                </TableCell>
                                <TableCell align="right">
                                    {parseFloat(dimension.sqftOfTall).toFixed(2) || 0.00}
                                </TableCell>
                            </TableRow>
                            : ""}
                            {dimension.checkedD ?
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Sqft of Island
                                </TableCell>
                                <TableCell align="right">
                                    {parseFloat(dimension.sqftOfIsland).toFixed(2) || 0.00}
                                </TableCell>
                            </TableRow>
                            : ""}
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Total Cabinetry Cost:
                                </TableCell>
                                <TableCell align="right">
                                    ₹ {dimension.cabinatry_cost ? (dimension.cabinatry_cost).toLocaleString('en-IN') : 0}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Save and Next'}
                </Button>
            </Box>
        </Box>
    )
}