import React, { useEffect } from "react";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default function Crockeryunit({
    editcrockeryunit,
    crockeryunit,
    handleSave
}) {
    let [dimension, setDimension] = React.useState({});
    let depth = ['Kitchen depth', 'Wardrobe depth'];
    useEffect(() => {
        DataProcessing(dimension);
    }, [dimension]);

    useEffect(() => {
        if (editcrockeryunit) {
            DataFetching(editcrockeryunit);
        }
    }, []);

    const DataFetching = (data) => {
        for ( var property in data ) {
            if(property === "dimensionA"){
                setDimension(prevState => ({ ...prevState, ["dimensionA"] : data.dimensionA }));
            } else if(property === "dimensionB"){
                setDimension(prevState => ({ ...prevState, ["dimensionB"] : data.dimensionB }));
            } else if(property === "dimensionD"){
                setDimension(prevState => ({ ...prevState, ["dimensionD"] : data.dimensionD }));
            } else if(property === "dimensionC"){
                if(data.dimensionC){
                    const finddata = crockeryunit['kitchandepth'].findIndex(item => {return data.dimensionC.id === item.id});
                    setDimension(prevState => ({ ...prevState, ["dimensionC"] : crockeryunit['kitchandepth'][finddata] }));
                }
            } else if(property === "dimensionE"){
                if(data.dimensionE){
                    const finddata = crockeryunit['wardrobedepth'].findIndex(item => {return data.dimensionE.id === item.id});
                    setDimension(prevState => ({ ...prevState, ["dimensionE"] : crockeryunit['wardrobedepth'][finddata] }));
                }
            } else {

            }
        }
    }

    const DataProcessing = (data) => {
        BackPanel(data.dimensionA || 0, data.dimensionB || 0, data.dimensionC || crockeryunit['kitchandepth'][0], data.dimensionD || "Kitchen depth",data.dimensionE || crockeryunit['wardrobedepth'][0]);
    }

    const BackPanel = (dimensionA, dimensionB, dimensionC, dimensionD, dimensionE) => {
        if(dimensionD === "Kitchen depth"){
            setDimension(prevState => ({ ...prevState, ['dimensionD']: dimensionD }));
            setDimension(prevState => ({ ...prevState, ['dimensionC']: dimensionC }));
            delete dimension.dimensionE;
            setDimension(prevState => ({ ...prevState, ['SqftOfBase']: ((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2) }));
            setDimension(prevState => ({ ...prevState, ['total_cost']: (parseFloat(((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2)) * parseInt(dimensionC['price'])).toFixed(2) }));
        } else if(dimensionD === "Wardrobe depth") {
            setDimension(prevState => ({ ...prevState, ['dimensionD']: dimensionD }));
            setDimension(prevState => ({ ...prevState, ['dimensionE']: dimensionE }));
            delete dimension.dimensionC;
            setDimension(prevState => ({ ...prevState, ['SqftOfBase']: ((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2) }));
            setDimension(prevState => ({ ...prevState, ['total_cost']: (parseFloat(((parseInt(dimensionA) * parseInt(dimensionB)) / 900).toFixed(2)) * parseInt(dimensionE['price'])).toFixed(2) }));
        } else {

        }
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        handleSave({
            name: "crockeryunit",
            value: dimension
        });
    }

    const handleChange = (e) => {
        setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }

    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            mt={3}
        >
            <Grid container spacing={2}>
                <Grid container mt={2} xs={12} sm={8}>
                    <Grid container xs={12} sm={12}>
                        <Grid item xs={12} sm={12}>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    select
                                    label="Choose depth"
                                    fullWidth
                                    name='dimensionD'
                                    value={dimension.dimensionD || 'Kitchen depth'}
                                    align="left"
                                    onChange={handleChange}
                                >
                                    {depth.map((item, index) => (
                                        <MenuItem key={index} value={item}>
                                            {item}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the Length of the Crockery Unit (In cm)"
                                    name="dimensionA"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionA || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                <TextField
                                    required
                                    fullWidth
                                    type={'number'}
                                    label="Enter the Height of the Crockery Unit (In cm)"
                                    name="dimensionB"
                                    autoFocus
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{ inputProps: { min: 0 } }}
                                    value={dimension.dimensionB || 0}
                                    onChange={handleChange}
                                />
                            </Box>
                            {dimension.dimensionD == "Wardrobe depth" ?
                                <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                    <TextField
                                        select
                                        label="Select the Wardrobe Depth Finish"
                                        fullWidth
                                        name='dimensionE'
                                        value={dimension.dimensionE || crockeryunit['wardrobedepth'][0]}
                                        align="left"
                                        onChange={handleChange}
                                    >
                                        {crockeryunit['wardrobedepth'].map((item) => (
                                            <MenuItem key={item.id} value={item}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            :
                                <Box component={'div'} mx={'auto'} mb={2} width={"95%"}>
                                    <TextField
                                        select
                                        label="Select the Kitchan Depth Finish"
                                        fullWidth
                                        name='dimensionC'
                                        value={dimension.dimensionC || crockeryunit['kitchandepth'][0]}
                                        align="left"
                                        onChange={handleChange}
                                    >
                                        {crockeryunit['kitchandepth'].map((item) => (
                                            <MenuItem key={item.id} value={item}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            }

                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Sqft of Crockery Unit:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.SqftOfBase || 0.00}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        Rate of the Crockery Unit:
                                    </TableCell>
                                    <TableCell align="right">
                                        ₹ {dimension.total_cost || 0.00}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" onClick={handleSubmit}>
                    Update
                </Button>
            </Box>
        </Box>
    )
}