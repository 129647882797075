import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AccessoryTable from "./component/AccessoryTable";

export default function IslandAccessoryForm({
    steps,
    activeStep,
    handleBack,
    handleNext,
    Accessorieslist,
    Accessory,
    AccessorySave,
})
{
    const [accessory, setAccessory] = React.useState(Accessorieslist[0]);
    const [quantity, setQuantity] = React.useState(1);
    let [totallist, setTotallist] = React.useState([]);

    const [error, setError] = React.useState({
        accessorytype: null,
        accessory: null,
        quantity: null,
        price: null
    });

    useEffect(() => {
        if(Accessory) {
            setTotallist(Accessory.accessorylist);
        }
    },[Accessory]);

    const Addlist = () => {
        if(totallist){
            const finddata = totallist.findIndex(item => {return accessory.id === item.id});
            // update
            if(totallist[finddata]){
                totallist[finddata].quantity = parseInt(quantity);
                totallist[finddata].totalcost = parseInt(accessory.price) * parseInt(quantity);
                setTotallist(totallist => [...totallist.slice(0, finddata), totallist[finddata], ...totallist.slice(finddata + 1)]);
            } else {
                // insert
                setTotallist(totallist => [...totallist, {
                    ...accessory,
                    quantity: parseInt(quantity),
                    totalcost: parseInt(accessory.price) * parseInt(quantity)
                }]);
            }
        } else {
            setTotallist(totallist => [...[], {
                ...accessory,
                quantity: parseInt(quantity),
                totalcost: parseInt(accessory.price) * parseInt(quantity)
            }]);
        }
    }

    const removeList = (data) => {
        setTotallist(totallist.filter((_, item) => item !== data.index));
    }

    const handleSubmit = () => {
        console.log(totallist);
        AccessorySave(totallist);
        // handleNext();
    }
    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            align="center"
            mt={3}
            >

            <Grid container>
                <Grid item xs={12} sm={8}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align={'left'}>
                        <TextField
                            select
                            fullWidth
                            label="Choose Accessory"
                            value={accessory}
                            onChange={e => setAccessory(e.target.value)}
                            helperText="Please Select your Accessory"
                            >
                            {Accessorieslist.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                {item.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                        <TextField
                            required
                            fullWidth
                            type={'number'}
                            label="Quantity"
                            autoFocus
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            error={error.quantity ? true : false}
                            helperText={error.quantity ? error.quantity : ""}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Fab color="primary" aria-label="add" onClick={Addlist}>
                        <AddIcon />
                    </Fab>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <AccessoryTable
                        columnlist={['Name', 'Quantity', 'Total', 'Action']}
                        datalist={totallist}
                        ondelete={removeList}
                    />
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    color="inherit"
                    disabled={activeStep !== 1 ? true : false}
                    sx={{ mr: 1 }}

                >
                    Close
                </Button>

                <Button variant="contained" onClick={handleSubmit}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Save and Next'}
                </Button>
            </Box>
        </Box>
    )
}