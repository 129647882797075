import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router-dom';
import ActionUrl from '../config/ActionUrl';
import Optionconfig from '../config/Optionconfig';

export default function Header(props) {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [name, setName] = React.useState('Jane Doe');
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    React.useEffect(() => {
        if(props.logindetails){
            setName(props.logindetails.name);
        }
    }, [props.logindetails])

    const handleLogout = async() => {
        Optionconfig({
            method: 'GET',
            url: ActionUrl.logout,
            tokens: localStorage.getItem("AuthData"),
            sendback: (response) => {
                if (response && response.status === 'success') {
                    props.signIn(false);
                    localStorage.removeItem("AuthData");
                    navigate('/login');
                }
            }
        });
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar variant="dense">
                    {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton> */}
                    <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                        <img src="./logopng.png" alt="logo" class="logo" />
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                            className="userBtn"
                        >
                            <AccountCircle /> <h5>{name}</h5>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {/* <MenuItem onClick={handleClose}>Profile</MenuItem>
                            <MenuItem onClick={handleClose}>My account</MenuItem> */}
                            <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </Menu>
                    </div>
                    {/* <div className="userProfile">
                        <div className="userDetails">
                            <AccountCircle/>
                            <h6>Jane Doe</h6>
                        </div>
                        <ul className="usersList">
                            <li>Profile</li>
                            <li>My Account</li>
                            <li>Logout</li>
                        </ul>
                    </div> */}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
