import React, { useEffect, useState } from "react"
import { Box, Button } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function CalcluateForm({
    steps,
    activeStep,
    handleBack,
    final,
}) {
    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            align="center"
            mt={3}
        >
            <Box
                component={'div'}
                width={"90%"}
                margin={'auto'}
            >
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Total Wardrobe Cost:
                                </TableCell>
                                <TableCell align="right">
                                    ₹ {final.wardrobeform_cost}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Total Accessories Cost:
                                </TableCell>
                                <TableCell align="right">
                                    ₹ {final.total_acc_cost}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row">
                                    Total Wardrobe Cost:
                                </TableCell>
                                <TableCell align="right">
                                    ₹ {final.total_cost}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                {/* <Button variant="contained" onClick={handleSubmit}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button> */}
            </Box>
        </Box>
    )
}