const config = require("./config");

const Apiurl = config.PORT == "" ? config.APIURL+"/api" : config.APIURL+":"+config.PORT+"/api";

const ActionUrl = {
    user: Apiurl+"/user",
    login: Apiurl+"/login",
    logout: Apiurl+"/logout",
    quotation: Apiurl+"/quotation",
    quotation_latest_no: Apiurl+"/quotation-quotation-no",
    quotationCollection: Apiurl+"/quotation-collection",
    quotationEdit: Apiurl+"/quotation-edit",
    quotationDelete: Apiurl+"/quotation-delete",
    customercreate: Apiurl+"/customer-create",
    customerkitchan: Apiurl+"/customer-kitchan",
    quotationChoosefinish: Apiurl+"/quotation-choosefinish",
    quotationAccessory: Apiurl+"/quotation-accessory",

    /* new function */
    storequotation: Apiurl+"/quotation-store",
};

export default ActionUrl;