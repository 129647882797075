import React from "react";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box } from "@mui/system";

const Footer = () => {
    return (
        <Box sx={{ my: 2 }}>
            <div className="text-center">
                <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://webohash.com/">
                    Webohash
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
                </Typography>
            </div>
        </Box>
    )
}

export default Footer;

