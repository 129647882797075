import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AccessoryTable from "./component/AccessoryTable";
import AccessoryTableLine from "./component/AccessoryTableLine";
import Switch from '@mui/material/Switch';

export default function UshapeAccessoryForm({
    steps,
    activeStep,
    handleBack,
    handleNext,
    Accessorieslist,
    Accessory,
    setFinal,
    Cabinetry,
    Kitchan,
    handleSave
}) {
    const [accessory, setAccessory] = React.useState(Accessorieslist[0]);
    const [quantity, setQuantity] = React.useState(1);
    let [totallist, setTotallist] = React.useState([]);
    const [dimension, setDimension] = React.useState({});

    // Extra Line Item with Description
    const [dimensionItem, setDimensionItem] = React.useState({});
    let [lineitemtotallist, setlineitemtotallist] = React.useState([]);

    const [error, setError] = React.useState({
        dimensionA: null,
        dimensionB: null
    });

    useEffect(() => {
        if (Accessory) {
            setTotallist(Accessory.total_accessory_list);
            setlineitemtotallist(Accessory.accessory_lineitem_lists);
        }
    }, [Accessory]);

    const handleChange = (e) => {
        if (e.target.name === "checkedA") {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        } else {
            setDimension(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }

    const handleChangeEvent = (e) => {
        if (e.target.name === "checkedA") {
            setDimensionItem(prevState => ({ ...prevState, [e.target.name]: e.target.checked }));
        } else {
            setDimensionItem(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    }

    const Addlist = () => {
        if (totallist) {
            const finddata = totallist.findIndex(item => { return accessory.id === item.id });
            // update
            if (totallist[finddata]) {
                totallist[finddata].quantity = parseInt(quantity);
                totallist[finddata].totalcost = parseInt(accessory.price) * parseInt(quantity);
                setTotallist(totallist => [...totallist.slice(0, finddata), totallist[finddata], ...totallist.slice(finddata + 1)]);
            } else {
                // insert
                setTotallist(totallist => [...totallist, {
                    ...accessory,
                    quantity: parseInt(quantity),
                    totalcost: parseInt(accessory.price) * parseInt(quantity)
                }]);
            }
        } else {
            setTotallist(totallist => [...[], {
                ...accessory,
                quantity: parseInt(quantity),
                totalcost: parseInt(accessory.price) * parseInt(quantity)
            }]);
        }
    }

    const removeList = (data) => {
        setTotallist(totallist.filter((_, item) => item !== data.index));
    }

    const removeExtraList = (data) => {
        setlineitemtotallist(lineitemtotallist.filter((_, item) => item !== data.index));
    }

    const AddExtralist = () => {
        if (!dimension.dimensionA) {
            setError({
                dimensionA: true
            });
        } else {
            if(totallist && totallist.length > 0){
                setTotallist(totallist => [...totallist, {
                    id: parseInt(Accessorieslist[Accessorieslist.length - 1].id) + parseInt(Math.floor((Math.random() * 10000) + 1)),
                    item: dimension.dimensionA,
                    size: 'Nil',
                    brand: '',
                    quantity: parseInt(dimension.quantity),
                    totalcost: parseInt(dimension.price) * parseInt(dimension.quantity)
                }]);
            } else {
                setTotallist(totallist => [...[], {
                    id: parseInt(Accessorieslist[Accessorieslist.length - 1].id) + parseInt(Math.floor((Math.random() * 10000) + 1)),
                    item: dimension.dimensionA,
                    size: 'Nil',
                    brand: '',
                    quantity: parseInt(dimension.quantity),
                    totalcost: parseInt(dimension.price) * parseInt(dimension.quantity)
                }]);
            }

            setError({
                dimensionA: false
            });

            setDimension({});
        }
    }

    const AddExtraItemlist = () => {
        if (!dimensionItem.dimensionB) {
            setError({
                dimensionB: true
            });
        } else {
            if(lineitemtotallist && lineitemtotallist.length > 0){
                setlineitemtotallist(lineitemtotallist => [...lineitemtotallist, {
                    id: parseInt(Accessorieslist[Accessorieslist.length - 1].id) + parseInt(Math.floor((Math.random() * 10000) + 1)),
                    item: dimensionItem.dimensionB,
                    size: 'Nil',
                    brand: '',
                    quantity: parseInt(dimensionItem.quantity),
                    description: dimensionItem.description,
                    totalcost: parseInt(dimensionItem.price) * parseInt(dimensionItem.quantity)
                }]);
            } else {
                setlineitemtotallist(lineitemtotallist => [...[], {
                    id: parseInt(Accessorieslist[Accessorieslist.length - 1].id) + parseInt(Math.floor((Math.random() * 10000) + 1)),
                    item: dimensionItem.dimensionB,
                    size: 'Nil',
                    brand: '',
                    quantity: parseInt(dimensionItem.quantity),
                    description: dimensionItem.description,
                    totalcost: parseInt(dimensionItem.price) * parseInt(dimensionItem.quantity)
                }]);
            }

            setError({
                dimensionB: false
            });

            setDimensionItem({});
        }
    }

    const handleSubmit = () => {
        let accessorytotalcost = 0;
        if (totallist) {
            accessorytotalcost = totallist.reduce((sum, a) => {
                return sum + a.totalcost;
            }, accessorytotalcost);
        }
        if (lineitemtotallist && lineitemtotallist.length > 0) {
            accessorytotalcost = lineitemtotallist.reduce((sum, a) => {
                return sum + a.totalcost;
            }, accessorytotalcost);
        }
        setFinal({
            total_accessory_list: totallist,
            total_accessory_line_list: lineitemtotallist,
            cabinatry_cost: Cabinetry['cabinatry_cost'],
            total_acc_cost: accessorytotalcost,
            total_cost: (parseFloat(Cabinetry['cabinatry_cost']) + parseFloat(accessorytotalcost)).toFixed(2)
        });
        handleSave({
            name: 'kitchan_interior',
            value: {
                kitchan: Kitchan,
                cabinetry: Cabinetry,
                accessory: {
                    total_accessory_list: totallist,
                    accessory_lineitem_lists: lineitemtotallist,
                    total_acc_cost: accessorytotalcost,
                },
                final: {
                    cabinatry_cost: Cabinetry['cabinatry_cost'],
                    total_acc_cost: accessorytotalcost,
                    total_cost: (parseFloat(Cabinetry['cabinatry_cost']) + parseFloat(accessorytotalcost)).toFixed(4)
                }
            }
        });
        handleNext();
    }
    return (
        <Box
            component={'form'}
            noValidate
            autoComplete="off"
            align="center"
            mt={3}
        >

            <Grid container>
                <Grid item xs={12} sm={8}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align={'left'}>
                        <TextField
                            select
                            fullWidth
                            label="Choose Accessory"
                            value={accessory}
                            onChange={e => setAccessory(e.target.value)}
                            helperText="Please Select your Accessory"
                        >
                            {Accessorieslist.map((item) => (
                                <MenuItem key={item.id} value={item}>
                                    {item.item + " " + item.brand}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                        <TextField
                            required
                            fullWidth
                            type={'number'}
                            label="Quantity"
                            autoFocus
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{ inputProps: { min: 0 } }}
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            error={error.quantity ? true : false}
                            helperText={error.quantity ? error.quantity : ""}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Fab color="primary" aria-label="add" onClick={Addlist}>
                        <AddIcon />
                    </Fab>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                        Any extra Line Item needed?
                        <Switch
                            name='checkedA'
                            checked={dimension.checkedA || false}
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                    {dimension.checkedA ?
                        <Grid container xs={12} sm={12}>
                            <Grid item xs={6} sm={6}>
                                <Box component={'div'} mx={'auto'} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Particulars"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        name="dimensionA"
                                        value={dimension.dimensionA || ""}
                                        onChange={handleChange}
                                        error={error.dimensionA ? true : false}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        type={'number'}
                                        label="Quantity"
                                        name="quantity"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={dimension.quantity || 0}
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        type={'number'}
                                        label="Price"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={dimension.price || 0}
                                        name="price"
                                        onChange={handleChange}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Fab color="primary" aria-label="add" onClick={AddExtralist}>
                                    <AddIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                        : ""}

                    <Grid item xs={12} sm={12} mb={2}>
                        <AccessoryTable
                            columnlist={['Name', 'Quantity', 'Total', 'Action']}
                            datalist={totallist}
                            ondelete={removeList}
                        />
                    </Grid>
                    <Box component={'div'} mx={'auto'} mb={2} width={"90%"} align="left">
                        Any extra Line Item With Description needed?
                        <Switch
                            name='checkedA'
                            checked={dimensionItem.checkedA || false}
                            onChange={handleChangeEvent}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                    {dimensionItem.checkedA ?
                        <Grid container xs={12} sm={12}>
                            <Grid item xs={6} sm={6}>
                                <Box component={'div'} mx={'auto'} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Particulars"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        name="dimensionB"
                                        value={dimensionItem.dimensionB || ""}
                                        onChange={handleChangeEvent}
                                        error={error.dimensionB ? true : false}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        type={'number'}
                                        label="Quantity"
                                        name="quantity"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={dimensionItem.quantity || 0}
                                        onChange={handleChangeEvent}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                                <Box component={'div'} mx={'auto'} mb={2} width={"90%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        type={'number'}
                                        label="Price"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        value={dimensionItem.price || 0}
                                        name="price"
                                        onChange={handleChangeEvent}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={10} sm={10} mb={2}>
                                <Box component={'div'} mx={'auto'} width={"94%"}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Description"
                                        autoFocus
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        name="description"
                                        value={dimensionItem.description || ""}
                                        onChange={handleChangeEvent}
                                        multiline
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Fab color="primary" aria-label="add" onClick={AddExtraItemlist}>
                                    <AddIcon />
                                </Fab>
                            </Grid>
                        </Grid>
                        : ""}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <AccessoryTableLine
                        columnlist={['Name', 'Quantity', 'Description', 'Total', 'Action']}
                        datalist={lineitemtotallist}
                        ondelete={removeExtraList}
                    />
                </Grid>
            </Grid>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                    color="inherit"
                    disabled={activeStep !== 1 ? true : false}
                    sx={{ mr: 1 }}

                >
                    Close
                </Button>

                <Button variant="contained" onClick={handleSubmit}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Save and Next'}
                </Button>
            </Box>
        </Box>
    )
}